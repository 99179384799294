import React from "react";
import {
  Home,
  Login,
  Applicants,
  Settings,
  Users,
  Feeds,
  Sites,
  Forms,
  Accounts,
} from "./pages";
import Reset from "./pages/login/reset.js";
import NewPassword from "./pages/login/newPassword.js";
import Company from "./pages/settings/changeCompany";
import Signup from "./pages/login/signup.js";
import Statuses from "./pages/applicants/statuses.js";
import Positions from "./pages/applicants/positions.js";
import Billing from "./pages/billing";
import axios from "axios";
import cookie from "js-cookie";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

const paths = [
  { path: "/feeds", component: Feeds, permission: "Feeds" },
  { path: "/sites", component: Sites, permission: "Sites" },
  { path: "/forms", component: Forms, permission: "Forms" },
  { path: "/users", component: Users, permission: "Users" },
  { path: "/accounts", component: Accounts, permission: "Accounts" },
  { path: "/applicants", component: Applicants, permission: "Applicants" },
  { path: "/positions", component: Positions, permission: "Positions" },
  { path: "/statuses", component: Statuses, permission: "Statuses" },
  { path: "/billing", component: Billing, permission: "Accounts" },
];

export class App extends React.Component {
  state = {
    routes: [],
  };

  getRoutes = () => {
    return axios.get("/user/current").then(async (res) => {
      if (res.data.success) {
        cookie.set("token", res.data.token);

        const allowed = res.data.allowed;
        let routes = [];
        if (res.data.user.superAdmin) this.setState({ routes: paths });
        else if (allowed == "admin")
          this.setState({
            routes: paths.filter((path) => path.permission != "Accounts"),
          });
        else {
          allowed &&
            paths.map((route) => {
              if (allowed.includes(route.permission)) routes.push(route);
            });
          this.setState({ routes: routes });
        }
      } else if (res.data.error === "Not Authorized" && cookie.get("token")) {
        window.location.reload();
      }
    });
  };

  componentDidMount() {
    this.getRoutes();
  }
  render() {
    const token = cookie.get("token");
    const { routes } = this.state;
    if (token)
      return (
        <Router>
          <Switch>
            <Route path="/home" component={Home} />
            <Route path="/changeCompany" component={Company} />
            <Route path="/settings" component={Settings} />
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                component={route.component}
              />
            ))}
            <Redirect to="/home" />
          </Switch>
        </Router>
      );
    else
      return (
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/reset" component={Reset} />
            <Route path="/newPassword" component={NewPassword} />
            <Route path="/signup" component={Signup} />
            <Redirect to="/login" />
          </Switch>
        </Router>
      );
  }
}

export default App;

const theme = {
  PAGE_BACKGROUND: "#4C4C4C",
  MAIN_COLOR: "#727272",
  BORDER_COLOR: "#727272",
  SHADOW_COLOR: "rgba(0,0,0,1)",
  INPUT_OUTLINE_COLOR: "rgba(0,0,0,.5)",
  INPUT_COLOR: "rgba(255,255,255,1)",
  BUTTON_COLOR: "rgb(255, 174, 53)",
  BUTTON_TEXT_COLOR: "black",
  BUTTON_HOVER_COLOR: "#286090",
  BORDER_COLOR: "#727272",
  NAV_TEXT_COLOR: "rgba(255, 174, 53)",
  NAV_BAR_COLOR: "#ff8e38",
  DARK_GREY: "#535354",
  LIGHT_GREY: "#79797A",
  LIGHTER_GREY: "#a39d9d",
  BLUE: "#6674ad", //"#586387",
  ORANGE: "rgba(255, 174, 53)",
  BLACK: "rgba(24,24,25,.95)",
  RED: "rgba(178, 34, 52,1)",
  WHITE: "#E8E5E5",
  BANNER_FONT: "sans-serif",
  MAIN_FONT: "sans-serif",
};

export default theme;

import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Button,
  Input,
  Notification,
  Modal,
  DatePicker,
  Clickable,
  Checkbox,
  Upload,
  Divider,
  Row,
} from "../../globalComponents";
import Test from "../../assets/birdTitle.png";
import FormData from "form-data";
import NewForm from "./NewForm.js";

class Forms extends React.Component {
  state = {
    forms: [],
    showArchived: false,
    form: {},
    fields: [],
    loading: false,
    error: {},
    values: {},
    emails: [],
    files: {},
    info: [],
    newFields: [],
    newDescription: "",
    newEmails: [],
    edit: null,
  };

  fetchForms = (resetForm) => {
    this.setState({ loading: true });
    axios
      .get("/forms", { params: { showArchived: this.state.showArchived } })
      .then((res) => {
        if (res.data.success) {
          if (res.data.forms.length === 0)
            this.setState({
              loading: false,
              forms: [{ label: "No Forms Found" }],
              form: {},
              values: {},
            });
          else {
            var temp = [];
            res.data.forms.map((form, index) =>
              temp.push({ label: form.name, value: form._id })
            );
            this.setState(
              {
                forms: temp,
                error: {},
                loading: false,
                form:
                  (resetForm || !this.state.form.name) && temp.length > 0
                    ? temp[0].value
                    : this.state.form,
              },
              () => !this.state.form.name && this.getForm()
            );
          }
        } else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  getForm = () => {
    this.setState({ loading: true });
    axios.get("/form", { params: { id: this.state.form } }).then((res) => {
      if (res.data.success) {
        let temp = {};
        res.data.form.fields.map(
          (field, index) => (temp[field._id] = temp.input === "image" ? [] : "")
        );
        this.setState({
          error: {},
          form: res.data.form,
          fields: res.data.form.fields,
          values: temp,
          loading: false,
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  archive = (index) => {
    this.setState({ loading: true });
    axios
      .post("/form/archive", {
        id: this.state.form._id,
      })
      .then((res) => {
        if (res.data.success)
          this.setState(
            {
              error: {},
              form: res.data.form,
              loading: false,
            },
            () => this.fetchForms(true)
          );
        else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };
  unarchive = (index) => {
    this.setState({ loading: true });
    axios
      .post("/form/unarchive", {
        id: this.state.form._id,
      })
      .then((res) => {
        if (res.data.success)
          this.setState(
            {
              error: {},
              form: res.data.form,
              loading: false,
            },
            () => this.fetchForms(true)
          );
        else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  addForm = async () => {
    axios
      .post("/form", {
        fields: this.state.newFields,
        name: this.state.newName,
        description: this.state.newDescription,
      })
      .then((res) => {
        if (res.data.success)
          this.setState(
            {
              error: {},
              form: res.data.form,
              loading: false,
            },
            this.fetchForms
          );
        else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };
  editForm = async () => {
    axios
      .post("/form", {
        id: this.state.form,
        fields: this.state.newFields,
        name: this.state.newName,
        description: this.state.newDescription,
      })
      .then((res) => {
        if (res.data.success) this.fetchForms();
        else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  submitForm = async () => {
    this.uploadFiles(this.state.values).then((values) => {
      if (values)
        axios
          .post("/applicant/anonymous", {
            id: this.state.form._id,
            values: values,
            version: this.state.form.version,
          })
          .then((res) => {
            if (res.data.success)
              this.setState({
                error: {},
                feed: res.data.feed,
                loading: false,
              });
            else if (res.data.error === "Not Authorized") {
              cookie.remove("token");
              window.location.reload();
            } else this.setState({ error: res.data.error, loading: false });
          });
    });
  };

  //Form Helper Functions
  changeValue = (id, value, select) => {
    var temp = this.state.values;
    if (select) temp[id] = value.target.value;
    else temp[id] = value;
    this.setState({ values: temp });
  };

  changenewFields = (name, index, value) => {
    var temp = this.state.newFields;
    temp[index][name] = value;
    this.setState({ newFields: temp });
  };

  changeOption = (index, option, remove) => {
    var temp = this.state.newFields;
    if (remove) {
      temp[index]["options"] = temp[index]["options"].filter(
        (item) => item !== option
      );
    } else temp[index]["options"].push(option);
    this.setState({ newFields: temp });
  };

  formatOptions = (options, multi) => {
    let temp = multi ? [] : [{ label: "Please Select One", value: "" }];
    options.map((option) => {
      temp.push({ label: option, value: option });
    });
    return temp;
  };

  displayFiles = (name, newFiles) => {
    const { files } = this.state;
    if (!newFiles) return [];
    var reader = new FileReader();
    const readFile = (index) => {
      if (index >= newFiles.length) return;
      var file = newFiles[index];
      reader.onloadend = (e) => {
        var temp = files;
        temp[name]
          ? temp[name].push(e.target.result)
          : (temp[name] = [e.target.result]);
        this.setState({ files: temp }, () => readFile(index + 1));
      };
      reader.readAsDataURL(file);
    };
    readFile(0);
  };

  uploadFiles = (vals) => {
    return new Promise((resolve, reject) => {
      const { values, fields } = this.state;
      var newVals = values;
      var images = [];

      fields.map((field, index) => {
        if (field.input == "image") {
          if (!!values[field._id].url) {
            values[field._id].index = index;
            images.push(values[field._id]);
          } else if (values[field._id].length > 0) {
            values[field._id].map((image) => {
              image.id = field._id;
              images.push(image);
            });
          }
        }
      });
      if (images.length < 1) resolve(vals);
      var count = 0;
      images.map((image, index) => {
        if (!image.signed) {
          count++;
          if (count === images.length) resolve(newVals);
          return;
        } else {
          var instance = axios.create();
          delete instance.defaults.headers.common["Authorization"];
          instance
            .put(image.signed, image.file, {
              headers: { "Content-Type": image.file.type },
            })
            .then((res) => {
              if ((res.statusText = "OK")) {
                if (typeof newVals[image.id] === "array")
                  newVals[image.id].push(image.url);
                else newVals[image.id] = [image.url];
                count++;
                if (count === images.length) {
                  resolve(newVals);
                }
              } else {
                reject("Error uploading files for storage");
              }
            });
        }
      });
    });
  };

  handleImages = (files, fieldID) => {
    const { error, values, form } = this.state;
    const signFile = (index) => {
      if (index >= files.length) {
        this.displayFiles(fieldID, files);
      } else
        axios
          .post("/form/file/upload", {
            fileName: fieldID + "_name_" + files[index].fieldID,
            fileType: files[index].type,
            file: files[index],
            formID: form._id,
          })
          .then((res) => {
            if (!res.data.success) {
              var temp = error;
              temp[index] = "Trouble uploading image try again";
              this.setState({ error: temp });
            } else {
              var newVals = values;
              newVals[fieldID]
                ? newVals[fieldID].push({
                    signed: res.data.aws_data.returnData.signedRequest,
                    url: res.data.aws_data.returnData.url,
                    file: files[index],
                  })
                : (newVals[fieldID] = [
                    {
                      signed: res.data.aws_data.returnData.signedRequest,
                      url: res.data.aws_data.returnData.url,
                      file: files[index],
                    },
                  ]);
              this.setState({ values: newVals }, () => {
                signFile(index + 1);
              });
            }
          })
          .catch((err) => {
            console.error(err);
            var temp = this.state.error;
            temp[index] = "Trouble uploading image try again";
            this.setState({ error: temp });
          });
    };
    signFile(0);
  };
  deleteImage = (index, fieldID) => {
    var temp = this.state.files;
    var newVals = this.state.values;
    temp[fieldID].splice(index, 1);
    newVals[fieldID].splice(index, 1);
    this.setState({ values: newVals, files: temp });
  };

  componentDidMount() {
    this.fetchForms();
  }
  render() {
    const {
      forms,
      form,
      fields,
      loading,
      error,
      add,
      values,
      emails,
      files,
      edit,
      newName,
      newDescription,
      newFields,
      newEmails,
      showArchived,
      info,
    } = this.state;
    const mobile = this.props.windowWidth < 800;
    return (
      <NavBar history={this.props.history} loading={loading}>
        <Section backgroundColor={"rgba(0,0,0,.3)"} height="92vh">
          <InfoBox margin="2% auto 1% auto" width="90vw">
            <Row>
              <Input
                name="form"
                label="Selected form"
                options={forms}
                margin="auto 1% 1% auto"
                fontSize={mobile ? "4vw" : "1vw"}
                width={mobile ? "80vw" : "40vw"}
                value={form._id}
                onChange={(v) =>
                  this.setState({ form: v.target.value }, this.getForm)
                }
              />
              <Checkbox
                label="Show archived forms"
                onChange={(v) =>
                  this.setState({ showArchived: v }, () =>
                    this.fetchForms(true)
                  )
                }
                value={showArchived}
              />
            </Row>
            <Row>
              <Button
                disabled={!form._id}
                onClick={() => {
                  if (showArchived) this.unarchive();
                  else this.archive();
                }}
                margin="auto 1% auto auto"
              >
                {showArchived ? "Un-Archive" : "Archive Form"}
              </Button>
              <Button
                disabled={!form._id || showArchived}
                margin="auto 1% auto 1%"
                onClick={() =>
                  this.setState({
                    edit: true,
                    newFields: fields,
                    newName: form.name,
                  })
                }
              >
                Edit Form
              </Button>
              <Button
                onClick={() =>
                  this.setState({ add: true, showArchived: false })
                }
                margin="auto 1% auto 1%"
              >
                Create New Form
              </Button>
            </Row>
          </InfoBox>
          <InfoBox margin="0 auto 0 auto" width="90vw">
            <Text margin="auto 0 1% 0" size="1vw">
              <Text textAlign="right">{"Version: " + form.version}</Text>
            </Text>
            <Row>
              <Text size="200%" margin="auto">
                {form.name}
              </Text>
            </Row>
            <Text size="100%" margin="auto">
              {form.description}
            </Text>
            {fields.map((field, index) => {
              if (field.input === "image")
                return (
                  <Upload
                    key={`input${index}`}
                    type={field.input}
                    name={field.label}
                    label={field.label}
                    files={files[field._id]}
                    error={error[field._id]}
                    onChange={(v) => this.handleImages(v, field._id)}
                    onRemove={(i) => this.deleteImage(i, field._id)}
                  />
                );
              else if (field.input === "section")
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "1% 0 1% 0",
                    }}
                  >
                    <Text>{field.label}</Text>
                    <Divider width="60%" color="black" />
                  </div>
                );
              else if (field.input === "checkbox")
                return (
                  <Checkbox
                    key={`input${index}`}
                    label={field.label}
                    value={values[field._id]}
                    margin="2% 2% auto 1%"
                    onChange={(v) => this.changeValue(field._id, v)}
                    error={error[field._id]}
                  />
                );
              else if (field.input === "multi-select")
                return (
                  <div>
                    <Text size="auto">{field.label}</Text>
                    {field.options &&
                      field.options.map((option, optionIndex) => (
                        <Checkbox
                          label={option}
                          onChange={(v) => {
                            let temp = values;
                            if (temp[field._id])
                              temp[field._id][optionIndex] = v;
                            else {
                              temp[field._id] = [];
                              temp[field._id][optionIndex] = v;
                            }
                            this.setState({ values: temp });
                          }}
                          value={
                            values[field._id] && values[field._id][optionIndex]
                          }
                          error={error[field._id]}
                        />
                      ))}
                  </div>
                );
              else
                return (
                  <Input
                    key={`input${index}`}
                    value={values[field._id]}
                    type={field.input}
                    name={field.label}
                    label={field.label}
                    error={error[field._id]}
                    multiline={field.input === "multiline"}
                    onChange={(v) =>
                      this.changeValue(field._id, v, field.options.length > 0)
                    }
                    options={
                      field.options.length > 0
                        ? this.formatOptions(field.options)
                        : null
                    }
                  />
                );
            })}
          </InfoBox>
        </Section>
        <NewForm
          add={add}
          mobile={mobile}
          edit={edit}
          onChange={this.changenewFields}
          newName={newName}
          newDescription={newDescription}
          changeName={(name) => this.setState({ newName: name })}
          changeDescription={(description) =>
            this.setState({ newDescription: description })
          }
          newFields={newFields}
          changeOption={this.changeOption}
          removeField={(index) => {
            let temp = newFields;
            temp.splice(index, 1);
            this.setState({ newFields: temp });
          }}
          moveField={(index, direction) => {
            let temp = newFields;
            let cur = temp[index];
            if (direction == "up") {
              temp[index] = temp[index - 1];
              temp[index - 1] = cur;
            } else {
              temp[index] = temp[index + 1];
              temp[index + 1] = cur;
            }
            this.setState({ newFields: temp });
          }}
          onClose={() =>
            this.setState(
              {
                add: false,
                edit: false,
                newName: "",
                newFields: [],
              },
              () => this.fetchForms()
            )
          }
          addField={() => {
            var temp = newFields;
            temp.push({
              input: "string",
              label: "",
              tableView: false,
              options: [],
            });
            this.setState({ newFields: temp });
          }}
          addSection={() => {
            var temp = newFields;
            temp.push({
              input: "section",
              label: "",
              tableView: false,
              options: [],
            });
            this.setState({ newFields: temp });
          }}
          onSubmit={() => {
            if (edit !== null) this.editForm();
            else this.addForm();
            this.setState(
              {
                add: false,
                edit: false,
                newFields: [],
                newEmails: [],
                files: [],
              },
              this.fetchForms
            );
          }}
        />
        <Notification
          onClose={() => this.setState({ error: {} })}
          open={error.general}
          title="Error getting forms"
          message={error.general}
        />
      </NavBar>
    );
  }
}

export default windowSize(Forms);

import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Input,
  Button,
} from "../../globalComponents";
import Theme from "../../globalComponents/Theme.js";

class Forms extends React.Component {
  state = {
    first: "",
    last: "",
    error: { name: "", text: "" },
    id: "",
  };
  getInfo() {
    this.setState({ loading: true });
    axios
      .get("/user/current")
      .then((res) => {
        if (res.data.success) {
          const accounts = res.data.accounts;
          var temp = [];
          accounts.forEach((account) => {
            temp.push({ label: account.name, value: account._id });
          });
          this.setState({
            error: { name: "", text: "" },
            accounts: temp,
            account: res.data.account._id,
            id: res.data.user._id,
            loading: false,
          });
        } else
          this.setState({
            error: { name: "general", text: res.data.error },
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: { name: "general", text: "Server Error Try Again" },
          loading: false,
        });
      });
  }
  settings() {
    this.setState({ loading: true });
    axios
      .post("/user/settings", {
        accountID: this.state.account,
        id: this.state.id,
      })
      .then((res) => {
        if (res.data.success) {
          cookie.set(
            "name",
            res.data.user.firstName + " " + res.data.user.lastName
          );
          var temp = {};
          cookie.set("token", res.data.token);
          cookie.set("account", res.data.account);
          this.setState(
            { error: { name: "", text: "" }, loading: false },
            window.location.reload()
          );
        } else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else
          this.setState({
            error: { name: "general", text: res.data.error, loading: false },
          });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          error: {
            name: "general",
            text: "Server Error Try Again",
            loading: false,
          },
        });
      });
  }
  componentDidMount() {
    this.getInfo();
  }
  render() {
    const { accounts, account, error, loading } = this.state;

    const mobile = this.props.windowWidth < 1000;
    return (
      <NavBar history={this.props.history} loading={loading}>
        <Section backgroundColor={"rgba(0,0,0,.3)"} height="92vh">
          <InfoBox
            maxWidth="600px"
            width={mobile ? "75vw" : "45vw"}
            margin="2% auto auto auto"
          >
            <Text size={mobile ? "6vw" : "2vw"} margin="2%">
              Change Current Company
            </Text>
            <Input
              label="Account"
              value={account}
              options={accounts}
              onChange={(v) => this.setState({ account: v.target.value })}
            />
            <Text color="red">{error.name == "general" && error.text}</Text>
            <Button
              width={"90%"}
              loading={loading}
              margin="1vh auto auto auto"
              onClick={() => this.settings()}
            >
              Submit
            </Button>
          </InfoBox>
        </Section>
      </NavBar>
    );
  }
}

export default windowSize(Forms);

import React, { Component } from "react";
import styled from "styled-components";
import Theme from "./Theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Label = styled.div`
  position: ${(props) => props.position};
  width: ${(props) => props.width || "auto"};
  font-family: ${Theme.MAIN_FONT};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "auto")};
  font-weight: bold;
  display: ${(props) => (props.hidden ? "none" : "default")};
  margin: 1% auto 1% 1%;
  color: ${(props) => (props.color ? props.color : "black")};
`;
const MultilineInput = styled.textarea`
  box-shadow: ${(props) =>
    props.shadow ? `0px 0px 1vw ${Theme.BLUE}` : "none"};
  border: none;
  background: none;
  outline: none;
  height: 100%;
  color: ${(props) => props.textColor};
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${(props) => props.fontSize || "100%"};
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  padding: 2px 1% 2px 1%;
  box-sizing: border-box;
  ::placeholder {
    color: ${(props) => props.hintColor};
  }
  ::-webkit-scrollbar-track {
    background-color: "grey";
  }
  ::-webkit-scrollbar-thrumb {
    border-radius: 12%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ErrorText = styled.div`
  color: red;
  font-size: 80%;
  margin-left: 1%;
`;

export default class Input extends Component {
  render() {
    const {
      onChange,
      error,
      name,
      placeholder,
      containerStyle,
      multiline,
      label,
      fontSize,
      labelSize,
      labelColor,
      textColor,
      backgroundColor,
      type,
      onKeyUp,
      errorColor,
      borderColor,
      borderRadius,
      border,
      margin,
      width,
      height,
      value,
      icon,
      options,
    } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: width || "99%",
          height: height || "auto",
          margin: margin || "auto",
        }}
      >
        <Label
          fontSize={fontSize}
          labelColor={labelColor}
          labelSize={labelSize}
        >
          {label}
        </Label>
        <div
          style={{
            border: border || ".15vw solid",
            borderColor: error
              ? errorColor || Theme.RED
              : borderColor || Theme.INPUT_OUTLINE_COLOR,
            borderRadius: borderRadius || "5px",
            backgroundColor: backgroundColor || Theme.INPUT_COLOR,
            flexDirection: "row",
            width: "100%",
            height: "100%",
          }}
        >
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              style={{
                margin: "auto auto auto 1%",
                width: fontSize + ".5" || "1.3vw",
                height: fontSize + ".5" || "1.3vw",
                color: "grey",
              }}
            />
          )}
          <MultilineInput
            id="textArea"
            {...this.props}
            onChange={(e) => {
              onChange(e.target.value, e);
            }}
            error={!!error}
            value={value || ""}
            multiline
            placeholder={placeholder}
            onKeyUp={onKeyUp}
          />
        </div>
        {!!error && <ErrorText>{error || "error"}</ErrorText>}
      </div>
    );
  }
}

import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import Theme from "./Theme.js";
import { Loader } from "./";

const Clickable = styled.button`
  outline: none;
  border: none;
  font-size: ${(props) => props.fontSize || "auto"};
  font-weight: 800;
  border: ${(props) => props.border};
  border-radius: ${(props) => props.radius || "10px"};
  background-color: ${(props) =>
    props.disabled
      ? Theme.LIGHTER_GREY
      : props.background || Theme.BUTTON_COLOR};
  color: ${Theme.BUTTON_TEXT_COLOR};
  cursor: ${(props) => props.disabled || "pointer"};
  margin: ${(props) => props.margin || "auto"};
  padding: ${(props) => props.padding || "1vh"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  animation: ${(props) => props.animation};
  animation-delay: ${(props) => props.animationDelay};
  animation-fill-mode: both;
  min-width: 100px;
  box-shadow: ${(props) =>
    props.shadow || "0px 0px 5px rgba(88, 99, 135, 0.6)"};
  &:hover {
    transition: font-size 1s ease, background 1s ease;
    font-size: ${(props) => props.fontSize + 0.5 || "auto"};
    background: ${(props) =>
      props.disabled ? null : props.hoverColor || Theme.BUTTON_HOVER_COLOR};
  }
`;
export default class Button extends Component {
  render() {
    const { onClick, children, loading, loaderSize, height } = this.props;
    return (
      <Clickable {...this.props} onClick={onClick}>
        {loading ? <Loader size={loaderSize || "1vw"} /> : children}
      </Clickable>
    );
  }
}

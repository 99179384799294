import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Button,
  Input,
  Notification,
  Modal,
  Divider,
  DatePicker,
  Clickable,
  Checkbox,
  Upload,
  Row,
} from "../../globalComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import Test from "../../assets/birdTitle.png";

class ApplicantForms extends React.Component {
  state = {
    edit: false,
  };

  formatOptions = (options) => {
    let temp = [{ label: "Please Select One", value: "" }];
    options.map((option) => {
      temp.push({ label: option, value: option });
    });
    return temp;
  };

  formatFiles = (files) => {
    let temp = [];
    files.length > 0 && files.map((image) => temp.push(image));
    return temp;
  };

  render() {
    const { edit } = this.state;
    const {
      form,
      fields,
      values,
      error,
      changeValue,
      handleImages,
      deleteImage,
      submitForm,
      deleteAnswer,
    } = this.props;
    const mobile = this.props.windowWidth < 800;
    return (
      <InfoBox margin="0 auto 0 auto" width={mobile ? "95vw" : "75vw"}>
        <Text> {form.name}</Text>
        <Row margin="-1%">
          <Clickable
            onClick={() => this.setState({ edit: true })}
            margin="0 1% auto auto"
          >
            <FontAwesomeIcon
              style={{ height: "40px", width: "40px" }}
              icon={faEdit}
            />
          </Clickable>
          <Clickable onClick={deleteAnswer} margin="0 -2% auto -1%">
            <FontAwesomeIcon
              style={{ color: "red", height: "40px", width: "40px" }}
              icon={faTimes}
            />
          </Clickable>
        </Row>
        {fields.map((field, index) => {
          if (field.input === "image")
            return (
              <Upload
                key={`input${index}`}
                type={field.input}
                name={field.label}
                disabled={!edit}
                label={field.label}
                files={values[field._id]}
                error={error[field._id]}
                onChange={(v) => handleImages(v, field._id)}
                onRemove={(i) => deleteImage(i, field._id)}
              />
            );
          else if (field.input === "section")
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1% 0 1% 0",
                }}
              >
                <Text>{field.label}</Text>
                <Divider width="60%" color="black" />
              </div>
            );
          else if (field.input === "checkbox")
            return (
              <Checkbox
                key={`input${index}`}
                disabled={!edit}
                label={field.label}
                value={values[field._id]}
                margin="2% 2% auto 1%"
                onChange={(v) => changeValue(field._id, v)}
                error={error[field._id]}
              />
            );
          else if (field.input === "multi-select")
            return (
              <div>
                <Text size="auto">{field.label}</Text>
                {field.options &&
                  field.options.map((option, optionIndex) => (
                    <Checkbox
                      disabled={!edit}
                      label={option}
                      onChange={(v) => {
                        let temp = values;
                        if (temp[field._id]) temp[field._id][optionIndex] = v;
                        else {
                          temp[field._id] = [];
                          temp[field._id][optionIndex] = v;
                        }
                        changeValue(field._id, v);
                      }}
                      value={values[field._id][optionIndex]}
                      error={error[field._id]}
                    />
                  ))}
              </div>
            );
          else
            return (
              <Input
                key={`input${index}`}
                disabled={!edit}
                value={values[field._id]}
                type={field.input}
                name={field.label}
                label={field.label}
                error={error[field._id]}
                multiline={field.input === "multiline"}
                onChange={(v) =>
                  changeValue(field._id, v, field.options.length > 0)
                }
                options={
                  field.options.length > 0
                    ? this.formatOptions(field.options)
                    : null
                }
              />
            );
        })}
      </InfoBox>
    );
  }
}

export default windowSize(ApplicantForms);

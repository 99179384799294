import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Input,
  Button
} from "../../globalComponents";
import Theme from "../../globalComponents/Theme.js";

class Forms extends React.Component {
  state = {
    email: "",
    first: "",
    last: "",
    error: { name: "", text: "" },
    id: ""
  };
  getInfo() {
    this.setState({ loading: true });
    axios
      .get("/user/current")
      .then(res => {
        if (res.data.success) {
          const user = res.data.user;
          this.setState({
            error: { name: "", text: "" },
            email: user.email,
            first: user.firstName,
            last: user.lastName,
            id: user._id,
            loading: false
          });
        } else
          this.setState({
            error: { name: "general", text: res.data.error },
            loading: false
          });
      })
      .catch(err => {
        this.setState({
          error: { name: "general", text: "Server Error Try Again" },
          loading: false
        });
      });
  }
  settings() {
    this.setState({ loading: true });
    axios
      .post("/user/settings", {
        email: this.state.email,
        firstName: this.state.first,
        lastName: this.state.last,
        id: this.state.id
      })
      .then(res => {
        if (res.data.success) {
          cookie.set(
            "name",
            res.data.user.firstName + " " + res.data.user.lastName
          );
          this.setState({ error: { name: "", text: "" }, loading: false });
        } else
          this.setState({
            error: { name: "general", text: res.data.error, loading: false },
            loading: false
          });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          error: {
            name: "general",
            text: "Server Error Try Again"
          },
          loading: false
        });
      });
  }
  componentDidMount() {
    this.getInfo();
  }
  render() {
    const { email, first, last, password, error, loading } = this.state;

    const mobile = this.props.windowWidth < 1000;
    return (
      <NavBar history={this.props.history} loading={loading}>
        <Section backgroundColor={"rgba(0,0,0,.3)"} height="92vh">
          <InfoBox
            maxWidth="600px"
            width={mobile ? "75vw" : "45vw"}
            margin="2% auto auto auto"
          >
            <Text size="4vh" margin="2%">
              Settings
            </Text>
            <Input
              label="Email"
              height="8vh"
              width={"90%"}
              value={email}
              onChange={v => this.setState({ email: v })}
            />
            <Input
              label="First Name"
              height="8vh"
              width={"90%"}
              value={first}
              onChange={v => this.setState({ first: v })}
            />
            <Input
              label="Last Name"
              height="8vh"
              width={"90%"}
              value={last}
              onChange={v => this.setState({ last: v })}
            />
            <Text color="red">{error.name == "general" && error.text}</Text>
            <Button
              width={"90%"}
              loading={loading}
              margin="1vh auto auto auto"
              onClick={() => this.settings()}
            >
              Submit
            </Button>
          </InfoBox>
        </Section>
      </NavBar>
    );
  }
}

export default windowSize(Forms);

import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Button,
  Input,
  Notification,
  Modal,
  Clickable,
  Upload,
  Row,
  Column,
  MultilineInput,
} from "../../globalComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import Test from "../../assets/birdTitle.png";
import FormData from "form-data";

class sites extends React.Component {
  state = {
    sites: [],
    site: null,
    loading: false,
    error: {},
    values: [],
    emails: [],
    backgrounds: [],
    files: {},
    info: [],
    newDomain: "",
    newInfo: [],
    newEmails: [],
    edit: {},
  };

  fetchCurrent = () => {
    this.setState({ loading: true });
    axios.get("/user/current").then(async (res) => {
      if (res.data.success) {
        cookie.set("token", res.data.token);
        this.setState({
          superAdmin: res.data.user.superAdmin,
          account: res.data.account,
          admin: res.data.user.admin,
          loading: false,
          allowed: res.data.allowed,
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  fetchSites = () => {
    this.setState({ loading: true });
    axios.get("/sites").then((res) => {
      if (res.data.success) {
        if (res.data.sites.length === 0)
          this.setState({ loading: false, sites: [], site: {} });
        else {
          var temp = [];
          res.data.sites.map((site, index) =>
            temp.push({ label: site.domain, value: site._id })
          );
          this.setState(
            {
              sites: temp,
              error: {},
              loading: false,
              site: temp.length > 0 ? temp[0].value : { emails: [] },
            },
            () => temp.length > 0 && this.getSite()
          );
        }
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  getSite = () => {
    this.setState({ loading: true });
    axios.get("/site", { params: { id: this.state.site } }).then((res) => {
      if (res.data.success)
        this.setState({
          error: {},
          site: res.data.site,
          values: res.data.site.info,
          emails: res.data.site.emails,
          backgrounds: res.data.site.backgrounds,
          loading: false,
        });
      else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  delete = (index) => {
    this.setState({ loading: true });
    axios
      .post("/site/deleteEntry", {
        id: this.state.site._id,
        index: index,
      })
      .then((res) => {
        if (res.data.success)
          this.setState({
            error: {},
            site: res.data.site,
            loading: false,
          });
        else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  addSite = async () => {
    axios
      .post("/site", {
        info: this.state.newInfo,
        domain: this.state.newDomain,
        emails: this.state.newEmails,
      })
      .then((res) => {
        if (res.data.success)
          this.setState(
            {
              error: {},
              site: res.data.site,
              loading: false,
            },
            this.fetchSites
          );
        else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };
  editSite = async () => {
    axios
      .post("/site", {
        id: this.state.site._id,
        info: this.state.values,
        emails: this.state.emails,
      })
      .then((res) => {
        if (res.data.success) this.getSite();
        else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  editSiteAdmin = async (index) => {
    const edit = this.state.edit;
    axios
      .post("/site", {
        id: edit._id,
        info: edit.info,
        domain: edit.domain,
        emails: edit.emails,
      })
      .then((res) => {
        if (res.data.success)
          this.setState({
            error: {},
            site: res.data.site,
            loading: false,
          });
        else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  openEdit = (index) => {
    const { site, files, values } = this.state;
    const entry = site.entries[index];
    const info = site.info;
    let tempFiles = files;
    let tempVals = values;
    info.map((info) => {
      if (info.input == "image") tempFiles[info.name] = entry[info.name];
      tempVals[info.name] = entry[info.name];
    });
    this.setState({ values: tempVals, files: tempFiles, edit: index });
  };
  changeValue = (index, value) => {
    var temp = this.state.values;
    temp[index].value = value;
    this.setState({ values: temp });
  };
  changeEmail = (index, value) => {
    var temp = this.state.emails;
    temp[index].value = value;
    this.setState({ emails: temp });
  };

  uploadFiles = (vals) => {
    return new Promise((resolve, reject) => {
      const { values, fields } = this.state;
      var newVals = values;
      var images = [];

      fields.map((field, index) => {
        if (field.input == "image") {
          if (!!values[field._id].url) {
            values[field._id].index = index;
            images.push(values[field._id]);
          } else if (values[field._id].length > 0) {
            values[field._id].map((image) => {
              image.id = field._id;
              images.push(image);
            });
          }
        }
      });
      if (images.length < 1) resolve(vals);
      var count = 0;
      images.map((image, index) => {
        if (!image.signed) {
          count++;
          if (count === images.length) resolve(newVals);
          return;
        } else {
          var instance = axios.create();
          delete instance.defaults.headers.common["Authorization"];
          instance
            .put(image.signed, image.file, {
              headers: { "Content-Type": image.file.type },
            })
            .then((res) => {
              if ((res.statusText = "OK")) {
                if (typeof newVals[image.id] === "array")
                  newVals[image.id].push(image.url);
                else newVals[image.id] = [image.url];
                count++;
                if (count === images.length) {
                  resolve(newVals);
                }
              } else {
                reject("Error uploading files for storage");
              }
            });
        }
      });
    });
  };

  handleImages = async (name, newFile, entryIndex) => {
    this.readFiles(entryIndex, newFile);
    const { error, site } = this.state;
    axios
      .post("/site/file/upload", {
        fileName: name + "-" + entryIndex + "_name_" + newFile.name,
        fileType: newFile.type,
        file: newFile,
        siteID: site._id,
      })
      .then((res) => {
        if (!res.data.success) {
          var temp = error;
          temp[name] = "Trouble uploading image try again";
          this.setState({ error: temp });
        } else {
          var temp = this.state.values;
          temp[entryIndex].value = {
            signed: res.data.aws_data.returnData.signedRequest,
            url: res.data.aws_data.returnData.url,
            file: newFile,
            field: name,
          };
          this.setState({ values: temp });
        }
      })
      .catch((err) => {
        console.error(err);
        var temp = this.state.error;
        temp[name] = "Trouble uploading image try again";
        this.setState({ error: temp });
      });
  };

  changeBackground = (page, files, index) => {
    const { error, site } = this.state;
    let file = files[0];
    axios
      .post("/site/file/upload", {
        fileName: page + "-" + index + "_name_" + file.name,
        fileType: file.type,
        file: file,
        siteID: site._id,
      })
      .then((res) => {
        if (!res.data.success) {
          var temp = error;
          temp[page] = "Trouble uploading image try again";
          this.setState({ error: temp });
        } else {
          var instance = axios.create();
          delete instance.defaults.headers.common["Authorization"];
          instance
            .put(res.data.aws_data.returnData.signedRequest, file, {
              headers: { "Content-Type": file.type },
            })
            .then((res2) => {
              if ((res2.statusText = "OK")) {
                axios
                  .post("/site/background", {
                    page,
                    file,
                    url: res.data.aws_data.returnData.signedRequest.split(
                      "?"
                    )[0],
                    id: site._id,
                    fileType: file.type,
                  })
                  .then((res3) => {
                    if (res3.data.success) {
                      this.setState({ site: site._id }, this.getSite);
                    } else {
                      var temp = error;
                      temp[page] = "Trouble uploading image try again";
                      this.setState({ error: temp });
                    }
                  });
              } else {
                var temp = error;
                temp[page] = "Trouble uploading image try again";
                this.setState({ error: temp });
              }
            });
        }
      })
      .catch((err) => {
        console.error(err);
        var temp = this.state.error;
        temp[page] = "Trouble uploading image try again";
        this.setState({ error: temp });
      });
  };
  readFiles = (index, newFile) => {
    const { files } = this.state;
    const tempFiles = Object.assign({}, this.state.files);
    var reader = new FileReader();
    reader.onloadend = (e) => {
      tempFiles[index] = [e.target.result];
      this.setState({ files: tempFiles });
    };
    console.log(newFile[0]);
    reader.readAsDataURL(newFile[0]);
  };
  deleteImage = (name, index) => {
    var temp = Object.assign({}, this.state.files);
    var newVals = Object.assign({}, this.state.values);
    temp.deleted
      ? temp.deleted.push(temp[name][index])
      : (temp.deleted = [temp[name][index]]);
    newVals[name].splice(index, 1);
    if (index !== 1 && newVals.length > 2) temp[name].splice(index, 1);
    this.setState({ values: newVals, files: temp });
  };
  changeNewInfo = (name, index, value) => {
    var temp = this.state.edit.info;
    temp[index][name] = value;
    this.setState({ newInfo: temp });
  };
  changeNewEmails = (name, index, value) => {
    var temp = this.state.edit.emails;
    temp[index][name] = value;
    this.setState({ newEmails: temp });
  };
  componentDidMount() {
    this.fetchSites();
    this.fetchCurrent();
  }
  render() {
    const {
      sites,
      site,
      loading,
      error,
      add,
      values,
      emails,
      backgrounds,
      files,
      edit,
      newDomain,
      newInfo,
      newEmails,
      info,
      superAdmin,
    } = this.state;
    const mobile = this.props.windowWidth < 800;
    return (
      <NavBar history={this.props.history} loading={loading}>
        <Section backgroundColor={"rgba(0,0,0,.3)"} height="92vh">
          <InfoBox margin="2% auto 1% auto" width="90vw">
            <Input
              name="site"
              label="Selected Site"
              options={sites}
              fontSize="1vw"
              width={mobile ? "80vw" : "40vw"}
              onChange={(v) => this.setState({ site: v }, this.fetchSites)}
            />
            {superAdmin && (
              <Row>
                <Button
                  onClick={() =>
                    this.setState({ add: true, edit: { emails: [], info: [] } })
                  }
                  margin="auto 1% auto auto"
                >
                  Add New Site
                </Button>
                <Button
                  onClick={() => this.setState({ edit: site })}
                  margin="auto 1% auto 1%"
                >
                  Edit Site
                </Button>
              </Row>
            )}
          </InfoBox>
          <InfoBox margin="0 auto 0 auto" width="90vw">
            <Text size={mobile ? "6vh" : "3vh"}> Site Info</Text>

            <Button onClick={this.editSite} margin="auto 1% auto auto">
              Save Changes
            </Button>
            {values.map((info, index) =>
              info.inputType == "image" ? (
                <Upload
                  single={true}
                  key={`input${index}`}
                  name={info.name}
                  label={info.name}
                  files={files[info._id]}
                  error={error[info._id]}
                  onChange={(v) => this.handleImages(info.name, v, info._id)}
                  onRemove={(i) => this.deleteImage(i, info._id)}
                />
              ) : info.inputType == "multiline" ? (
                <MultilineInput
                  key={`input${index}`}
                  height="25vh"
                  value={values[index].value}
                  type={info.inputType}
                  name={info.name}
                  label={info.name}
                  error={error[info.name]}
                  onChange={(v) => this.changeValue(index, v)}
                  options={info.options}
                />
              ) : (
                <Input
                  key={`input${index}`}
                  value={values[index].value}
                  type={info.inputType}
                  name={info.name}
                  label={info.name}
                  error={error[info.name]}
                  onChange={(v) => this.changeValue(index, v)}
                  options={info.options}
                />
              )
            )}
          </InfoBox>
          <InfoBox
            margin="0 auto 0 auto"
            width="90vw"
            display={emails.length > 0 ? "flex" : "none"}
          >
            <Text size={mobile ? "6vh" : "3vh"}>Emails</Text>
            {emails &&
              emails.map((email, index) => (
                <Input
                  key={`input${index}`}
                  value={site.emails && site.emails[index].value}
                  name={email.name}
                  label={email.name}
                  error={error[info.name]}
                  onChange={(v) => this.changeEmail(index, v)}
                />
              ))}
            <Button onClick={this.editSite} margin="2% auto auto auto">
              Save Changes
            </Button>
          </InfoBox>{" "}
          <InfoBox
            margin="0 auto 0 auto"
            width="90vw"
            display={backgrounds.length > 0 ? "flex" : "none"}
          >
            <Text size={mobile ? "6vh" : "3vh"}>Backgrounds</Text>
            {backgrounds &&
              backgrounds.map((background, index) => (
                <Column>
                  <Text size={mobile ? "4vh" : "2vh"}>{background.page}</Text>
                  <img
                    src={background.url}
                    style={{
                      height: "auto",
                      width: mobile ? "90%" : "50%",
                      margin: "auto",
                    }}
                  />
                  <Upload
                    single={true}
                    key={`input${index}`}
                    type={background.fileType}
                    name={background.page}
                    files={files[background.page]}
                    error={error[background.page]}
                    onChange={(v) =>
                      this.changeBackground(background.page, v, index)
                    }
                    onRemove={(i) => this.deleteImage(i, background.page)}
                  />
                </Column>
              ))}
          </InfoBox>
        </Section>
        <Notification
          onClose={() => this.setState({ error: {} })}
          open={error.general}
          title="Error getting sites"
          message={error.general}
        />
        <Modal
          open={edit._id || add}
          width={mobile ? "100vw" : "90vw"}
          onClose={() =>
            this.setState(
              { add: false, edit: { emails: [] }, files: {}, values: [] },
              this.fetchSites
            )
          }
        >
          <Input
            value={edit.domain}
            type={"string"}
            name={"newDomain"}
            label="Domain"
            error={error.domain}
            onChange={(v) => {
              let temp = Object.assign({}, edit);
              temp.domain = v;
              console.log(edit);
              edit
                ? this.setState({ edit: temp })
                : this.setState({ newDomain: v });
            }}
          />
          {edit.info &&
            edit.info.map((field, index) => (
              <div
                style={{
                  backgroundColor: index % 2 ? "white" : "rgba(0,0,0,.2)",
                  margin: "1% 0 1% 0",
                }}
              >
                <Row noWrap={!mobile}>
                  <Input
                    width="10vw"
                    value={field.name}
                    type={"string"}
                    name={"name"}
                    label="Field Name"
                    onChange={(v) => this.changeNewInfo("name", index, v)}
                  />
                  <Input
                    key={`input${index}`}
                    width="10vw"
                    type="string"
                    name="inputType"
                    label="Field Type"
                    onChange={(v) =>
                      this.changeNewInfo("inputType", index, v.target.value)
                    }
                    options={[
                      { label: "String", value: "string" },
                      { label: "Image", value: "image" },
                      { label: "multiline", value: "multiline" },
                    ]}
                  />
                  <Clickable
                    onClick={() => {
                      let temp = Object.assign({}, edit);
                      temp.info.splice(index, 1);
                      this.setState({ edit: temp });
                    }}
                    margin="0 0 auto auto"
                  >
                    <FontAwesomeIcon
                      style={{ color: "red", height: "30px", width: "30px" }}
                      icon={faTimes}
                    />
                  </Clickable>
                </Row>
                {field.inputType !== "image" && (
                  <Input
                    value={field.value}
                    width="90%"
                    type={"string"}
                    name={"name"}
                    label="Field Value"
                    onChange={(v) => this.changeNewInfo("value", index, v)}
                  />
                )}
              </div>
            ))}
          <Button
            margin="2% auto auto auto"
            width="100%"
            onClick={() => {
              var temp = Object.assign({}, edit);
              temp.info.push({ inputType: "string", value: "", name: "" });
              this.setState({ edit: temp });
            }}
          >
            Add Info
          </Button>
          {edit.emails &&
            edit.emails.map((email, index) => (
              <Row>
                <Input
                  value={email.name}
                  width={mobile ? "100%" : "45%"}
                  type={"string"}
                  name={"name"}
                  label="Name/Description"
                  placeholder="Default"
                  onChange={(v) => this.changeNewEmails("name", index, v)}
                />
                <Input
                  value={email.value}
                  width={mobile ? "100%" : "45%"}
                  type={"string"}
                  name={"name"}
                  label="Email"
                  onChange={(v) => this.changeNewEmails("value", index, v)}
                />
                <Clickable
                  onClick={() => {
                    let temp = Object.assign({}, edit);
                    temp.emails.splice(index, 1);
                    this.setState({ edit: temp });
                  }}
                  margin="0 0 auto auto"
                >
                  <FontAwesomeIcon
                    style={{ color: "red", height: "30px", width: "30px" }}
                    icon={faTimes}
                  />
                </Clickable>
              </Row>
            ))}
          <Button
            margin="2% auto auto auto"
            width="100%"
            onClick={() => {
              var temp = Object.assign({}, edit);
              temp.emails.push({ email: "", name: "" });
              this.setState({ newEmail: temp });
            }}
          >
            Add Email
          </Button>
          <Button
            margin="2% auto 2% auto"
            width="100%"
            onClick={() => {
              if (edit !== null) this.editSiteAdmin(edit);
              else this.addSite();
              this.setState(
                {
                  add: false,
                  edit: {},
                  files: {},
                  values: [],
                },
                this.fetchSites
              );
            }}
          >
            {add ? "Create Site" : "Edit Site"}
          </Button>
        </Modal>
      </NavBar>
    );
  }
}

export default windowSize(sites);

import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Button,
  Input,
  Notification,
  Modal,
  Clickable,
  Slideshow,
  Upload,
  Row,
} from "../../globalComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Test from "../../assets/birdTitle.png";
import FormData from "form-data";
import moment from "moment";

class Feeds extends React.Component {
  state = {
    feeds: [],
    feed: null,
    loading: false,
    error: {},
    values: {},
    files: {},
    edit: null,
    filesToAdd: [],
    editFeed: { format: [], entries: [] },
  };

  fetchCurrent = () => {
    this.setState({ loading: true });
    axios.get("/user/current").then(async (res) => {
      if (res.data.success) {
        cookie.set("token", res.data.token);
        this.setState({
          superAdmin: res.data.user.superAdmin,
          account: res.data.account,
          admin: res.data.user.admin,
          loading: false,
          allowed: res.data.allowed,
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  fetchFeeds = () => {
    this.setState({ loading: true });
    axios.get("/feeds").then((res) => {
      if (res.data.success) {
        var temp = [];
        res.data.feeds.map((feed, index) =>
          temp.push({ label: feed.name, value: feed._id })
        );
        this.setState(
          {
            feeds: temp,
            error: {},
            loading: false,
            feed: temp.length > 0 && temp[0].value,
          },
          () => temp.length > 0 && this.getFeed()
        );
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  getFeed = () => {
    this.setState({ loading: true });
    axios.get("/feed", { params: { id: this.state.feed } }).then((res) => {
      if (res.data.success)
        this.setState({ error: {}, feed: res.data.feed, loading: false });
      else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  delete = (index) => {
    this.setState({ loading: true });
    axios
      .post("/feed/deleteEntry", {
        id: this.state.feed._id,
        index: index,
      })
      .then((res) => {
        if (res.data.success)
          this.setState({
            error: {},
            feed: res.data.feed,
            loading: false,
          });
        else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  addEntry = async () => {
    this.uploadFiles(this.state.values).then((values) => {
      if (values)
        axios
          .post("/feed/addEntry", {
            id: this.state.feed._id,
            entry: values,
          })
          .then((res) => {
            if (res.data.success)
              this.setState({
                error: {},
                feed: res.data.feed,
                loading: false,
                files: {},
                filesToAdd: [],
                values: {},
                add: false,
              });
            else if (res.data.error === "Not Authorized") {
              cookie.remove("token");
              window.location.reload();
            } else this.setState({ error: res.data.error, loading: false });
          });
    });
  };

  editEntry = async (index) => {
    this.uploadFiles(this.state.values).then((values) => {
      var temp = this.state.feed.entries;
      temp[index] = values;
      if (values)
        axios
          .post("/feed", {
            id: this.state.feed._id,
            entries: temp,
            removedFiles: this.state.files.deleted,
          })
          .then((res) => {
            if (res.data.success)
              this.setState(
                {
                  error: {},
                  feed: res.data.feed,
                  filesToAdd: [],
                  edit: null,
                  files: {},
                  values: {},
                  loading: false,
                },
                this.fetchFeeds
              );
            else if (res.data.error === "Not Authorized") {
              cookie.remove("token");
              window.location.reload();
            } else this.setState({ error: res.data.error, loading: false });
          });
    });
  };

  editFeed = async () => {
    axios
      .post("/feed", {
        id: this.state.editFeed._id,
        format: this.state.editFeed.format,
        name: this.state.editFeed.name,
      })
      .then((res) => {
        if (res.data.success)
          this.setState(
            {
              loading: false,
            },
            this.fetchFeeds
          );
        else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  openEdit = (index) => {
    const { feed, files, values } = this.state;
    const entry = feed.entries[index];
    const format = feed.format;
    let tempFiles = Object.assign({}, files);
    let tempVals = Object.assign({}, values);
    format.map((field) => {
      if (field.input == "image") tempFiles[field.name] = entry[field.name];
      tempVals[field.name] = entry[field.name];
    });
    this.setState({ values: tempVals, files: tempFiles, edit: index });
  };
  changeValue = (name, value) => {
    var temp = this.state.values;
    temp[name] = value;
    this.setState({ values: temp });
  };

  uploadFiles = (vals) => {
    return new Promise((resolve, reject) => {
      const { feed, values, filesToAdd } = this.state;
      const format = feed.format;
      var newVals = Object.assign({}, values);
      var imageNames = [];
      var count = 0;
      if (filesToAdd.length > 0)
        filesToAdd.map((file, index) => {
          var instance = axios.create();
          delete instance.defaults.headers.common["Authorization"];
          instance
            .put(file.signed, file.file, {
              headers: { "Content-Type": file.file.type },
            })
            .then((res) => {
              if ((res.statusText = "OK")) {
                newVals[file.field]
                  ? newVals[file.field].push(file.signed.split("?")[0])
                  : (newVals[file.field] = [file.signed.split("?")[0]]);
                count++;
                if (count >= filesToAdd.length) {
                  newVals[file.field] = newVals[file.field].filter(
                    (image) => image.substring(0, 4) != "data"
                  );
                  console.log("this");
                  resolve(newVals);
                }
              } else {
                reject("Error uploading files for storage");
              }
            });
        });
      else resolve(newVals);
    });
  };
  readFiles = (name, newFiles) => {
    const { files } = this.state;
    const tempFiles = Object.assign({}, this.state.files);
    const temp = tempFiles[name];
    if (!newFiles) return [];
    var reader = new FileReader();
    const readFile = (index, temp) => {
      if (index >= newFiles.length) {
        tempFiles[name] = temp;
        this.setState({ files: tempFiles });
        return tempFiles;
      }
      var file = newFiles[index];
      reader.onloadend = (e) => {
        if (temp) {
          temp.push(e.target.result);
        } else temp = [e.target.result];
        readFile(index + 1, temp);
      };
      reader.readAsDataURL(file);
    };
    readFile(0, temp);
  };

  handleImages = async (name, newFiles, entryIndex) => {
    this.readFiles(name, newFiles);
    const { error, feed } = this.state;
    let signedImages = [];
    const signFile = (index) => {
      if (index < signFile.length)
        axios
          .post("/feed/file/upload", {
            fileName:
              name +
              "-" +
              moment().format("dMMYYHHmmss") +
              "_name_" +
              newFiles[index].name,
            fileType: newFiles[index].type,
            file: newFiles[index],
            feedId: feed._id,
          })
          .then((res) => {
            if (!res.data.success) {
              var temp = error;
              temp[name] = "Trouble uploading image try again";
              this.setState({ error: temp });
            } else {
              signedImages.push({
                signed: res.data.aws_data.returnData.signedRequest,
                url: res.data.aws_data.returnData.url,
                file: newFiles[index],
                field: name,
              });
              signFile(index + 1);
            }
          })
          .catch((err) => {
            console.error(err);
            var temp = this.state.error;
            temp[name] = "Trouble uploading image try again";
            this.setState({ error: temp });
          });
      else
        this.setState({
          filesToAdd: signedImages,
        });
    };
    signFile(0);
  };

  deleteImage = (name, index) => {
    var temp = Object.assign({}, this.state.files);
    var newVals = Object.assign({}, this.state.values);
    temp.deleted
      ? temp.deleted.push(temp[name][index])
      : (temp.deleted = [temp[name][index]]);
    newVals[name].splice(index, 1);
    if (index !== 1 && newVals.length > 2) temp[name].splice(index, 1);
    this.setState({ values: newVals, files: temp });
  };
  componentDidMount() {
    this.fetchFeeds();
    this.fetchCurrent();
  }
  render() {
    const {
      feeds,
      feed,
      loading,
      error,
      add,
      superAdmin,
      values,
      files,
      edit,
      editFeed,
      addFeed,
    } = this.state;
    const mobile = this.props.windowWidth < 800;
    return (
      <NavBar history={this.props.history} loading={loading}>
        <Section backgroundColor={"rgba(0,0,0,.3)"} height="92vh">
          <InfoBox margin="2% auto 1% auto" width="90vw">
            <Input
              name="feed"
              label="Selected Feed"
              options={feeds}
              fontSize="1vw"
              width={mobile ? "80vw" : "40vw"}
              onChange={(v) =>
                this.setState({ feed: v.target.value }, this.getFeed)
              }
            />
            {superAdmin && (
              <Row>
                <Button
                  onClick={() =>
                    this.setState({
                      addFeed: true,
                      editFeed: { format: [], entries: [] },
                    })
                  }
                  margin="auto 1% auto auto"
                >
                  Add New Feed
                </Button>
                <Button
                  onClick={() => this.setState({ editFeed: feed })}
                  margin="auto 1% auto 1%"
                >
                  Edit Feed
                </Button>
              </Row>
            )}
            <Button
              onClick={() => this.setState({ add: true })}
              margin="1% 1% auto auto"
            >
              Add Entry
            </Button>
          </InfoBox>
          {feed &&
            feed.entries &&
            feed.entries.map((entry, index1) => (
              <InfoBox
                key={`input${index1}`}
                width="90vw"
                margin="2% auto auto auto"
              >
                <Row margin="-1%">
                  <Clickable
                    onClick={() => this.openEdit(index1)}
                    margin="0 1% auto auto"
                  >
                    <FontAwesomeIcon
                      style={{ height: "40px", width: "40px" }}
                      icon={faEdit}
                    />
                  </Clickable>
                  <Clickable
                    onClick={() => this.delete(index1)}
                    margin="0 -2% auto -1%"
                  >
                    <FontAwesomeIcon
                      style={{ color: "red", height: "40px", width: "40px" }}
                      icon={faTimes}
                    />
                  </Clickable>
                </Row>
                {feed.format &&
                  feed.format.map((input, index2) => (
                    <div key={`input${index2}`}>
                      <Text size="2vw" margin="2% auto 2% 0" textAlign="left">
                        {input.label}
                      </Text>
                      {input.input === "image" ? (
                        entry &&
                        entry[input.name] &&
                        entry[input.name].length > 0 && (
                          <Slideshow
                            margin="1%"
                            height="40vh"
                            images={entry[input.name]}
                          />
                        )
                      ) : (
                        <Text margin="2% auto 2% 0" textAlign="center">
                          {entry && entry[input.name]}
                        </Text>
                      )}
                    </div>
                  ))}
              </InfoBox>
            ))}
        </Section>
        <Notification
          onClose={() => this.setState({ error: {} })}
          open={error.general}
          title="Error getting feeds"
          message={error.general}
        />
        <Modal
          open={add || edit !== null}
          width={mobile ? "100vw" : "90vw"}
          onClose={() =>
            this.setState(
              { add: false, edit: null, files: {}, values: {} },
              this.fetchFeeds
            )
          }
        >
          {feed &&
            feed.format &&
            feed.format.map((format, index) => {
              if (format.input === "image" || format.input === "images")
                return (
                  <Upload
                    single={format.input == "image"}
                    key={`input${index}`}
                    type={format.input}
                    name={format.name}
                    label={format.label}
                    files={files[format.name]}
                    error={error[format.name]}
                    onChange={(v) => this.handleImages(format.name, v, index)}
                    onRemove={(index) => this.deleteImage(format.name, index)}
                  />
                );
              else
                return (
                  <Input
                    key={`input${index}`}
                    value={values[format.name]}
                    type={format.input}
                    name={format.name}
                    label={format.label}
                    error={error[format.name]}
                    multiline={format.input === "multiline"}
                    onChange={(v) => this.changeValue(format.name, v)}
                    options={format.options.length > 0 ? format.options : null}
                  />
                );
            })}
          <Button
            margin="2% auto auto auto"
            width="100%"
            onClick={() => {
              if (edit !== null) this.editEntry(edit);
              else this.addEntry();
            }}
          >
            {edit !== null ? "Edit Entry" : "Add Entry"}
          </Button>
        </Modal>
        <Modal
          open={addFeed || editFeed._id}
          width={mobile ? "100vw" : "90vw"}
          onClose={() =>
            this.setState(
              {
                addFeed: false,
                editFeed: { format: [] },
              },
              this.fetchFeeds
            )
          }
        >
          <Input
            value={editFeed.name}
            name="name"
            label={"Feed Name"}
            error={!editFeed.name && "Name Required"}
            onChange={(v) => {
              let temp = Object.assign({}, editFeed);
              temp.name = v;
              this.setState({ editFeed: temp });
            }}
          />
          {editFeed.format.map((format, index) => (
            <Row>
              <Input
                key={`${index}name`}
                value={editFeed.name}
                name="name"
                label={"Entry Format Name"}
                error={!editFeed.name && "Name Required"}
                onChange={(v) => {
                  let temp = Object.assign({}, editFeed);
                  temp.name = v;
                  this.setState({ editFeed: temp });
                }}
              />
              <Input
                key={`input${index}`}
                width="10vw"
                type="string"
                name="inputType"
                label="Field Type"
                onChange={(v) =>
                  this.changeNewInfo("inputType", index, v.target.value)
                }
                options={[
                  { label: "String", value: "string" },
                  { label: "Image", value: "image" },
                  { label: "multiline", value: "multiline" },
                ]}
              />
            </Row>
          ))}
          <Button
            margin="2% auto auto auto"
            width="100%"
            onClick={() => this.editFeed}
          >
            {editFeed._id !== null ? "Edit Entry" : "Add Entry"}
          </Button>
        </Modal>
      </NavBar>
    );
  }
}

export default windowSize(Feeds);

import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  InfoBox,
  Input,
  Image,
  Row,
  Column,
  Button,
  Modal,
  Text,
} from "../../globalComponents";
import Logo from "../../assets/logos/Main_Logo.png";
import Page_Background from "../../assets/Page_Background.jpg";
import Theme from "../../globalComponents/Theme.js";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    error: { name: "", text: "" },
  };
  login = () => {
    this.setState({ loading: true });
    axios
      .post("/user/login", {
        email: this.state.email,
        password: this.state.password,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ error: { name: "", text: "", loading: false } });
          cookie.set("token", res.data.token);
          cookie.set("account", res.data.account);
          if (res.data.resetPWD) {
            this.setState({
              loading: false,
              password: "",
              updatePWD: res.data.resetPWD,
            });
          } else {
            window.location.reload();
          }
        } else
          this.setState({
            error: { general: res.data.error },
            loading: false,
          });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          error: { name: "general", text: "Server Error Try Again" },
          loading: false,
        });
      });
  };

  updatePassword = () => {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      updatePWD,
    } = this.state;
    this.setState({ loading: true });
    let tempErr = {};
    if (!firstName || !lastName || !password || password !== confirmPassword) {
      if (!firstName) tempErr.firstName = "Required";
      if (!lastName) tempErr.lastName = "Required";
      if (!password) tempErr.password = "Required";
      if (password !== confirmPassword)
        tempErr.general = "Passwords must match";
      this.setState({ loading: false, error: tempErr });
    } else
      axios
        .post("/user/password", {
          firstName: firstName,
          lastName: lastName,
          code: updatePWD,
          password: password,
        })
        .then((res) => {
          if (res.data.success) {
            cookie.set(
              "name",
              res.data.user.firstName + " " + res.data.user.lastName
            );
            window.location.reload();
          } else
            this.setState({
              loading: false,
              error: { name: "general", text: res.data.error },
            });
        });
  };
  render() {
    const {
      email,
      password,
      error,
      loading,
      updatePWD,
      firstName,
      lastName,
      confirmPassword,
    } = this.state;
    const mobile = this.props.windowWidth < 1000;

    return (
      <Column>
        <img
          src={Page_Background}
          style={{
            position: "fixed",
            height: "100%",
            minWidth: "100vw",
            zIndex: -1,
          }}
        />
        <InfoBox
          padding="0"
          margin="10vh auto auto auto"
          borderRadius="20px"
          maxWidth="600px"
          width={mobile ? "100vw" : "45vw"}
        >
          <Column width="100%">
            <Image src={Logo} height={mobile ? "20vh" : "10vh"} />
            <Text size="200%" margin="-5% auto 2% auto">
              Aviary
            </Text>
            <Text size="100%" margin="0 auto 2% auto">
              Nitely's Content Management System
            </Text>
          </Column>
          <Input
            label="Email"
            value={email}
            height={"100%"}
            width={"70%"}
            onChange={(v) => this.setState({ email: v })}
          />
          <Input
            width="70%"
            height={"100%"}
            label="Password"
            value={password}
            type="password"
            onChange={(v) => this.setState({ password: v })}
            onKeyUp={(e) => {
              if (e.key === "Enter") this.login();
            }}
          />
          <Text margin="auto" color="red">
            {error.general}
          </Text>
          <Button
            width={"70%"}
            loading={loading}
            margin="3% auto 3% auto"
            onClick={this.login}
          >
            Login
          </Button>
          <Row
            noWrap
            width={mobile ? "90%" : "70%"}
            padding={mobile ? "1%" : "1% 15% 1% 15%"}
          >
            <Button
              width="100%"
              margin="auto 3% auto auto"
              onClick={() => this.props.history.push("/reset")}
            >
              Reset Password
            </Button>
            <Button
              width="100%"
              onClick={() => this.props.history.push("/signup")}
            >
              Create an Account
            </Button>
          </Row>
        </InfoBox>
        <Modal open={updatePWD}>
          <Text>Please complete your profile </Text>
          <Input
            label="First Name"
            value={firstName}
            height="6vh"
            width={"70%"}
            error={error.firstName}
            onChange={(v) => this.setState({ firstName: v })}
          />
          <Input
            label="Last Name"
            value={lastName}
            height="6vh"
            width={"70%"}
            error={error.lastName}
            onChange={(v) => this.setState({ lastName: v })}
          />
          <Input
            width="70%"
            height="6vh"
            label="Password"
            value={password}
            type="password"
            onChange={(v) => this.setState({ password: v })}
          />
          <Input
            width="70%"
            height="6vh"
            label="Confirm Password"
            value={confirmPassword}
            type="password"
            onChange={(v) => this.setState({ confirmPassword: v })}
            onKeyUp={(e) => {
              if (e.key === "Enter") this.updatePassword();
            }}
          />
          <Text margin="auto" color="red">
            {error.general}
          </Text>
          <Button
            width={"70%"}
            loading={loading}
            margin="3% auto 3% auto"
            onClick={this.updatePassword}
          >
            Update Password
          </Button>
        </Modal>
      </Column>
    );
  }
}
export default windowSize(Login);

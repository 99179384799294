import React from "react";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Button,
  Input,
  Column,
  Notification,
  Modal,
  Clickable,
  Checkbox,
  Upload,
  Row,
  Divider
} from "../../globalComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowCircleUp,
  faArrowCircleDown
} from "@fortawesome/free-solid-svg-icons";

export default class NewForm extends React.Component {
  state = { option: [], tasks: [] };

  checkViewable = input => {
    let temp = ["multiline", "image", "multi-select"];
    return !temp.includes(input);
  };

  onDragOver = e => {
    e.preventDefault();
    console.log("heres");
  };

  onDragStart = (e, index) => {
    e.preventDefault();
    e.dataTransfer.setData("index", index);
    console.log("moving index: ", index);
  };

  onDrop = e => {
    let index = e.dataTransfer.getData("index");
    console.log("moved: ", index);
  };
  render() {
    const {
      add,
      edit,
      newFormName,
      newFields = [],
      onChange,
      changeOption,
      removeField,
      newName,
      onSubmit,
      onClose,
      addField,
      addSection,
      changeName,
      changeDescription,
      newDescription,
      mobile,
      moveField
    } = this.props;

    const { option } = this.state;

    return (
      <Modal
        open={add || edit}
        width={mobile ? "100vw" : "90vw"}
        onClose={onClose}
      >
        <Row>
          <Input
            value={newName}
            width="40%"
            type={"string"}
            name={"newFormName"}
            label="Form Name"
            margin="0 auto auto auto"
            onChange={v => changeName(v)}
          />

          <Input
            multiline
            label="Form Description"
            margin="auto 4% 4% auto"
            fontSize={mobile ? "4vw" : "1vw"}
            rows="2"
            width="40%"
            value={newDescription}
            onChange={v => changeDescription(v)}
          />
        </Row>
        {newFields.map((field, index) =>
          field.input === "section" ? (
            <div
              draggable={true}
              onDragStart={e => this.onDragStart(e, index)}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "1% 0 1% 0"
              }}
            >
              <Clickable
                margin="-1% 2px 0 auto"
                onClick={() => removeField(index)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "auto",
                    height: "2vh",
                    color: "red"
                  }}
                />
              </Clickable>
              <Input
                width="50%"
                margin="auto auto 1% auto"
                value={field.label}
                type={"string"}
                name={"label"}
                label="Section Title"
                onChange={v => onChange("label", index, v)}
              />
              <Divider width="60%" color="black" />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: index % 2 ? "white" : "rgba(0,0,0,.2)",
                padding: "1% 0 .5% 0"
              }}
            >
              <Clickable
                margin="-1% 2px 0 auto"
                onClick={() => removeField(index)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "auto",
                    height: "2vh",
                    color: "red"
                  }}
                />
              </Clickable>

              <Row noWrap={!mobile} width="100%">
                <Column margin="auto 1% auto 1%" width="auto">
                  <Clickable
                    margin="-1% 2px 0 0"
                    disabled={index == 0}
                    onClick={() => index !== 0 && moveField(index, "up")}
                  >
                    <FontAwesomeIcon
                      icon={faArrowCircleUp}
                      style={{
                        width: "auto",
                        height: "2vh",
                        color: index == 0 ? "grey" : "black"
                      }}
                    />
                  </Clickable>
                  <Text size="200%" margin="auto">
                    {index + 1}
                  </Text>
                  <Clickable
                    margin="-1% 2px 0 0"
                    disabled={index == newFields.length - 1}
                    onClick={() =>
                      index !== newFields.length - 1 && moveField(index, "down")
                    }
                  >
                    <FontAwesomeIcon
                      icon={faArrowCircleDown}
                      style={{
                        width: "auto",
                        height: "2vh",
                        color: index == newFields.length - 1 ? "grey" : "black"
                      }}
                    />
                  </Clickable>
                </Column>
                <Input
                  width="50%"
                  margin="auto auto auto 0"
                  value={field.label}
                  type={"string"}
                  name={"label"}
                  label="Field Label"
                  onChange={v => onChange("label", index, v)}
                />
                <Input
                  key={`input${index}`}
                  width="auto"
                  margin="auto auto auto 0"
                  type="string"
                  name="type"
                  label="Field Type"
                  value={field.input}
                  onChange={v => onChange("input", index, v.target.value)}
                  options={[
                    { label: "Text", value: "string" },
                    { label: "Image", value: "image" },
                    { label: "Multiline Text", value: "multiline" },
                    { label: "Date", value: "date" },
                    { label: "Time", value: "time" },
                    { label: "Checkbox", value: "checkbox" },
                    { label: "Selection", value: "select" },
                    { label: "Multi-Select", value: "multi-select" }
                  ]}
                />

                {edit && !edit.deletable && this.checkViewable(field.input) && (
                  <Checkbox
                    reverse
                    margin={mobile ? "auto" : "auto 2% auto 2%"}
                    value={field.tableView}
                    name={"name"}
                    label="Included In Table View"
                    onChange={v => onChange("tableView", index, v)}
                  />
                )}
              </Row>
              {field.input === "select" || field.input === "multi-select" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "2%"
                  }}
                >
                  <Row width={mobile ? "100%" : "50%"} margin="auto">
                    <Input
                      value={option[index]}
                      onChange={v => {
                        let temp = this.state.option;
                        temp[index] = v;
                        this.setState({ option: temp });
                      }}
                      width={mobile ? "100vw" : "40%"}
                      label="Add Options To The Selection"
                    />
                    <Button
                      onClick={() => {
                        changeOption(index, option[index]);
                        let temp = this.state.option;
                        temp[index] = "";
                        this.setState({ option: temp });
                      }}
                    >
                      Add Option
                    </Button>
                  </Row>
                  <Text margin="auto auto 0  auto" textAlign="center">
                    Option List
                  </Text>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      border: "2px solid black",
                      width: mobile ? "95%" : "50%",
                      margin: "0 auto 0 auto",
                      padding: "1%"
                    }}
                  >
                    {field.options &&
                      field.options.map(option => (
                        <Row>
                          <Text size={mobile ? "2vw" : "1vw"}>{option}</Text>
                          <Clickable
                            margin="auto 0 auto auto"
                            onClick={() => changeOption(index, option, true)}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              style={{
                                height: "2vh",
                                width: "auto",
                                color: "red"
                              }}
                            />
                          </Clickable>
                        </Row>
                      ))}
                  </div>
                </div>
              ) : null}
            </div>
          )
        )}
        <Row noWrap>
          <Button margin="2% auto auto auto" width="100%" onClick={addSection}>
            Add New Section
          </Button>
          <Button margin="2% auto auto 2%" width="100%" onClick={addField}>
            Add New Field
          </Button>
        </Row>
        <Button
          margin="2% auto auto auto"
          width="100%"
          onClick={() => onSubmit()}
        >
          {edit ? "Save Changes" : "Create form"}
        </Button>
      </Modal>
    );
  }
}

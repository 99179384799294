import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Button,
  Input,
  Divider,
  Notification,
  Modal,
  DatePicker,
  Clickable,
  Checkbox,
  Upload,
  Row,
  Column,
} from "../../globalComponents";
import Theme from "../../globalComponents/Theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTimes,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import Test from "../../assets/birdTitle.png";

class Statuses extends React.Component {
  state = {
    statuses: [],
    processes: [],
    statusOptions: [],
    forms: [],
    users: [],
    timer: [],
  };

  fetchStatuses = (selected) => {
    this.setState({ loading: true });
    axios.get("/statuses").then((res) => {
      if (res.data.success) {
        var temp = [{ label: "No Status Selected", value: null }];
        res.data.statuses.map((status, index) =>
          temp.push({ label: status.label, value: status._id })
        );
        this.setState({
          statuses: res.data.statuses,
          statusOptions: temp,
          error: {},
          loading: false,
          editStatuses: true,
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  fetchProcesses = (selected) => {
    this.setState({ loading: true });
    axios.get("/processes").then((res) => {
      if (res.data.success) {
        var temp = [{ label: "No Status Selected", value: null }];
        res.data.processes.map((process, index) =>
          temp.push({ label: process.label, value: process._id })
        );
        this.setState({
          processes: res.data.processes,
          processOptions: temp,
          error: {},
          loading: false,
          editStatuses: true,
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  fetchForms = () => {
    this.setState({ loading: true });
    axios
      .get("/forms", { params: { showArchived: this.state.showArchived } })
      .then((res) => {
        if (res.data.success) {
          if (res.data.forms.length === 0)
            this.setState({
              loading: false,
              forms: [{ label: "No Forms Found" }],
              form: {},
              values: [],
            });
          else {
            var temp = [{ label: "Select a Form to add" }];
            res.data.forms.map(
              (form, index) =>
                form.deleteable &&
                temp.push({
                  label: form.name,
                  form: form,
                  value: temp.length,
                })
            );
            this.setState({
              forms: temp,
              error: {},
              loading: false,
              form: temp.length > 0 && temp[0].value,
            });
          }
        } else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  fetchUsers = () => {
    this.setState({ loading: true });
    axios.get("/users").then((res) => {
      if (res.data.success) {
        var temp = [{ label: "Select a User to add", value: null }];
        res.data.users.map((user, index) =>
          temp.push({
            label: user.firstName
              ? user.firstName + " " + user.lastName
              : user.email,
            user: user,
            value: index + 1,
          })
        );
        this.setState({
          users: temp,
          error: {},
          loading: false,
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  submitStatus = (status, process) => {
    this.setState({ loading: true });
    axios
      .post("/status/", {
        id: status._id,
        label: status.label,
        nextStatus: status.nextStatus,
        forms: status.forms,
        users: status.users,
        userMessage: status.userMessage,
        applicantMessage: status.applicantMessage,
      })
      .then((res) => {
        if (res.data.success) {
          if (process) {
            process.statuses.push(res.data.status._id);
            this.submitProcess(process);
            if (process.statuses.length > 1) {
              this.submitStatus({
                _id: process.statuses[process.statuses.length - 2],
                nextStatus: res.data.status._id,
              });
            }
          }
          this.fetchStatuses();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };
  submitProcess = (process) => {
    this.setState({ loading: true });
    axios
      .post("/process/", {
        id: process._id,
        name: process.name,
        statuses: process.statuses,
      })
      .then((res) => {
        if (res.data.success) {
          this.fetchProcesses();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  removeStatus = (status, process) => {
    this.setState({ loading: true });
    axios
      .post("/process/removeStatus", {
        statusID: status._id,
        processID: process._id,
      })
      .then((res) => {
        if (res.data.success) {
          this.fetchProcesses();
          this.fetchStatuses();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  moveStatus = (status1, status2) => {
    this.setState({ loading: true });
    axios
      .post("/status/swap", {
        status1: status1._id,
        status2: status2._id,
      })
      .then((res) => {
        if (res.data.success) {
          this.fetchProcesses();
          this.fetchStatuses();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  filterForms(forms, status) {
    if (status.forms && status.forms.length > 0)
      status.forms.forEach((form1, index) => {
        forms = forms.filter((form2) => {
          return !form2.form || form2.form._id !== form1;
        });
        if (index + 1 >= status.forms.length) return;
      });
    return forms;
  }

  formInfo(form) {
    let temp = {};
    if (this.state.forms.length > 0)
      this.state.forms.forEach((form1, index) => {
        if (!form1.form || form1.form._id.toString() == form) temp = form1;
      });
    return temp;
  }

  userName(user) {
    let temp = {};
    if (this.state.users.length > 0)
      this.state.users.forEach((user1, index) => {
        if (!user1.user || user1.user._id.toString() == user) temp = user1;
      });
    return temp.label;
  }
  filterUsers(users, status) {
    let temp = users;
    if (status.users.length > 0)
      status.users.forEach((user1, index) => {
        users = users.filter((user2) => {
          if (!user2.user) return true;
          else return user2.user._id.toString() !== user1;
        });
        if (index + 1 >= status.users.length) return;
      });
    return users;
  }

  getProcessStatuses(processStatuses) {
    const { statuses } = this.state;
    let temp = [];
    processStatuses.map((pStatus) =>
      statuses.map((status) => {
        if (status._id.toString() == pStatus) temp.push(status);
      })
    );
    return temp;
  }

  editForms(action, status, formIndex) {
    console.log(formIndex);
    console.log(this.state.forms);
    if (action === "remove") status.forms.splice(formIndex, 1);
    else if (action === "add")
      status.forms.push(this.state.forms[formIndex].form);
    this.submitStatus(status);
  }
  editUsers(action, status, userIndex) {
    let temp = this.state.statuses;
    if (action === "remove") status.users.splice(userIndex, 1);
    else if (action === "add")
      status.users.push(this.state.users[userIndex].user);
    this.submitStatus(status);
  }

  changeValue(status, name, value, index) {
    let temp = this.state.statuses;
    let tempTimer = this.state.timer;
    status[name] = value;
    temp[index] = status;
    if (tempTimer[index]) {
      clearTimeout(tempTimer[index][name]);
      tempTimer[index][name] = setTimeout(
        () => this.submitStatus(status),
        2000
      );
    } else
      tempTimer[index] = {
        [name]: setTimeout(() => this.submitStatus(status), 2000),
      };
    this.setState({ statuses: temp, timer: tempTimer });
  }

  componentDidMount() {
    this.fetchStatuses();
    this.fetchProcesses();
    this.fetchForms();
    this.fetchUsers();
  }

  render() {
    const {
      forms,
      statuses,
      processes,
      users,
      selected,
      newLabel,
      newStatus,
      newProcess,
      existingStatus,
      selectedStatus,
      statusOptions,
      loading,
    } = this.state;
    const mobile = this.props.windowWidth < 800;
    return (
      <NavBar history={this.props.history} loading={loading}>
        <InfoBox
          width={mobile ? "100vw" : "90vw"}
          margin="2% auto auto auto"
          padding="2% 0 2% 0"
        >
          <Text size={mobile ? "4vw" : "2.5vw"} textAlign="center">
            Recruitment Processes
          </Text>
          <Button
            margin="auto 2% auto auto"
            onClick={() => this.setState({ newProcess: true })}
          >
            New Process
          </Button>
          <Divider width="95%" margin="1% auto 2% auto" />
          {processes.map((process, index) => (
            <Column width="95%" border="4px solid black">
              <Clickable
                onClick={() =>
                  this.setState({ selected: selected == index ? null : index })
                }
                background={Theme.MAIN_COLOR}
              >
                <Text size={mobile ? "3vw" : "2vw"} textAlign="center">
                  {process.name}
                </Text>
              </Clickable>
              <Column display={selected != index && "none"}>
                {this.getProcessStatuses(process.statuses).map(
                  (status, statusIndex) => (
                    <Column
                      width="100%"
                      backgroundColor={
                        statusIndex % 2 !== 0 && Theme.LIGHTER_GREY
                      }
                    >
                      {
                        //   <Clickable
                        //     margin="auto"
                        //     width="5%"
                        //     disabled={statusIndex == 0}
                        //     onClick={() =>
                        //       statusIndex !== 0 &&
                        //       this.moveStatus(
                        //         status,
                        //         this.getProcessStatuses(process.statuses)[
                        //           statusIndex - 1
                        //         ]
                        //       )
                        //     }
                        //   >
                        //     <FontAwesomeIcon
                        //       rotation={90}
                        //       icon={faExchangeAlt}
                        //       style={{
                        //         width: "auto",
                        //         height: "2vh",
                        //         color: statusIndex == 0 ? "grey" : "black",
                        //       }}
                        //     />
                        //   </Clickable>
                      }
                      <Clickable
                        margin="0 2% -4% auto"
                        width={mobile ? "3%" : "2%"}
                        onClick={() => this.removeStatus(status, process)}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{
                            height: "100%",
                            width: "auto",
                            color: "red",
                          }}
                        />
                      </Clickable>
                      <Row margin="2% auto 2% auto">
                        <Input
                          width="45%"
                          type="text"
                          label="Label"
                          value={status.label}
                          onChange={(v) =>
                            this.changeValue(status, "label", v, statusIndex)
                          }
                        />
                      </Row>
                      <Row margin="auto auto 2% auto">
                        <Column
                          margin="0 auto auto auto"
                          width={mobile ? "95%" : "45%"}
                        >
                          <Input
                            name="form"
                            label="Add a form"
                            options={this.filterForms(forms, status)}
                            value={0}
                            margin="0 1% 1% auto"
                            fontSize={mobile ? "4vw" : "1vw"}
                            onChange={(v) =>
                              this.editForms("add", status, v.target.value)
                            }
                          />
                          <div
                            style={{
                              backgroundColor: "white",
                              borderRadius: "5px",
                              border: "2px solid black",
                              width: "95%",
                              minHeight: "30px",
                              margin: "0 auto 0 auto",
                              padding: "1%",
                            }}
                          >
                            {status.forms.map((form, formIndex) => (
                              <Row>
                                <Text size={mobile ? "2vw" : "1vw"}>
                                  {this.formInfo(form).label}
                                </Text>
                                <Clickable
                                  margin="auto 0 auto auto"
                                  onClick={() =>
                                    this.editForms("remove", status, formIndex)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{
                                      height: "2vh",
                                      width: "auto",
                                      color: "red",
                                    }}
                                  />
                                </Clickable>
                              </Row>
                            ))}
                          </div>
                        </Column>
                        <Column
                          margin="0 auto auto auto"
                          width={mobile ? "95%" : "45%"}
                        >
                          <Input
                            name="user"
                            label="Users to Notify"
                            options={this.filterUsers(users, status)}
                            value={0}
                            margin="0 1% 1% auto"
                            fontSize={mobile ? "4vw" : "1vw"}
                            onChange={(v) =>
                              this.editUsers("add", status, v.target.value)
                            }
                          />

                          <div
                            style={{
                              backgroundColor: "white",
                              borderRadius: "5px",
                              border: "2px solid black",
                              minHeight: "30px",
                              width: "95%",
                              margin: "0 auto 0 auto",
                              padding: "1%",
                            }}
                          >
                            {status.users.map((user, userIndex) => (
                              <Row>
                                <Text size={mobile ? "2vw" : "1vw"}>
                                  {this.userName(user)}
                                </Text>
                                <Clickable
                                  margin="auto 0 auto auto"
                                  onClick={() =>
                                    this.editUsers("remove", status, userIndex)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{
                                      height: "2vh",
                                      width: "auto",
                                      color: "red",
                                    }}
                                  />
                                </Clickable>
                              </Row>
                            ))}
                          </div>
                        </Column>
                      </Row>
                      <Row>
                        <Input
                          multiline
                          label="Applicant Message"
                          margin="auto 4% 4% auto"
                          fontSize={mobile ? "4vw" : "1vw"}
                          rows="4"
                          width="40%"
                          value={status.applicantMessage}
                          onChange={(v) =>
                            this.changeValue(
                              status,
                              "applicantMessage",
                              v,
                              statusIndex
                            )
                          }
                        />
                        <Input
                          multiline
                          label="User Message"
                          margin="auto 4% 4% auto"
                          fontSize={mobile ? "4vw" : "1vw"}
                          width="40%"
                          rows="4"
                          placeholder="Messages will not be sent if there are no users to notify"
                          value={status.userMessage}
                          onChange={(v) =>
                            this.changeValue(
                              status,
                              "userMessage",
                              v,
                              statusIndex
                            )
                          }
                        />
                      </Row>

                      <Clickable
                        margin="auto auto -1.5vh auto"
                        width="5%"
                        disabled={
                          statusIndex + 1 ==
                          this.getProcessStatuses(process.statuses).length
                        }
                        onClick={() =>
                          statusIndex + 1 !=
                            this.getProcessStatuses(process.statuses).length &&
                          this.moveStatus(
                            status,
                            this.getProcessStatuses(process.statuses)[
                              statusIndex + 1
                            ]
                          )
                        }
                      >
                        <FontAwesomeIcon
                          rotation={90}
                          icon={faExchangeAlt}
                          style={{
                            width: "auto",
                            height: "3vh",
                            display:
                              statusIndex + 1 ==
                              this.getProcessStatuses(process.statuses).length
                                ? "none"
                                : "",
                          }}
                        />
                      </Clickable>
                    </Column>
                  )
                )}
                <Row>
                  <Button
                    margin="2% auto 2% auto"
                    width="80%"
                    onClick={() => this.setState({ newStatus: process })}
                  >
                    Add New Status
                  </Button>
                </Row>
              </Column>
            </Column>
          ))}
          <Modal
            open={newStatus}
            onClose={() => this.setState({ newStatus: false, newLabel: "" })}
          >
            <Input
              type="text"
              label="Label"
              value={newLabel}
              onChange={(v) => this.setState({ newLabel: v })}
            />
            <Button
              margin="2% auto auto auto"
              onClick={() => {
                this.submitStatus({ label: newLabel }, newStatus);
                this.setState({ newStatus: false, newLabel: "" });
              }}
            >
              Save New Status
            </Button>
          </Modal>
          <Modal
            open={newProcess}
            onClose={() => this.setState({ newProcess: false, newLabel: "" })}
          >
            <Input
              type="text"
              label="Process Name"
              value={newLabel}
              onChange={(v) => this.setState({ newLabel: v })}
            />
            <Button
              margin="2% auto auto auto"
              onClick={() => {
                this.submitProcess({ name: newLabel });
                this.setState({ newProcess: false, newLabel: "" });
              }}
            >
              Create New Process
            </Button>
          </Modal>
        </InfoBox>
      </NavBar>
    );
  }
}

export default windowSize(Statuses);

import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Input,
  Button,
  MultiSelectList,
  Modal,
  Row,
  Clickable,
  Table
} from "../../globalComponents";
import Theme from "../../globalComponents/Theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

const permissionOptions = [
  { label: "Site", value: "Sites" },
  { label: "Feeds", value: "Feeds" },
  { label: "Positions", value: "Positions" },
  { label: "Applicants", value: "Applicants" },
  { label: "Statuses", value: "Statuses" },
  { label: "Forms", value: "Forms" },
  { label: "Users", value: "Users" }
];

class Home extends React.Component {
  state = {
    accounts: [],
    account: {},
    users: [],
    remove: {},
    statuses: [],
    edit: {},
    add: false,
    email: ""
  };

  fetchUsers = () => {
    this.setState({ loading: true });
    axios.get("/users").then(res => {
      if (res.data.success) {
        this.setState({
          users: res.data.users,
          error: {},
          loading: false
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  fetchAccounts = () => {
    this.setState({ loading: true });
    axios.get("/user/current").then(res => {
      if (res.data.success) {
        var temp = res.data.user.accounts;
        let accounts = [];
        temp.map(account => {
          accounts.push({ label: account.name, value: account.database });
        });
        this.setState(
          {
            accounts: accounts,
            account: res.data.account,
            error: {},
            loading: false
          },
          this.fetchUsers
        );
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  fetchProcesses = selected => {
    this.setState({ loading: true });
    axios.get("/processes").then(res => {
      if (res.data.success) {
        var temp = [];
        res.data.processes.map((process, index) =>
          temp.push({ label: process.name, value: process._id })
        );
        this.setState(
          {
            processes: temp,
            processID: temp[0] ? temp[0].value : null,
            error: {},
            loading: false
          },
          this.fetchStatuses
        );
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  fetchStatuses = () => {
    this.setState({ loading: true });
    axios
      .get("/process/statuses", {
        params: { processID: this.state.processID }
      })
      .then(res => {
        if (res.data.success) {
          var temp = [];
          res.data.statuses.map((status, index) => {
            temp.push({ label: status.label, value: status._id, status });
          });
          this.setState({
            statuses: temp,
            error: {},
            loading: false
          });
        } else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  removeUser = () => {
    this.setState({ loading: true });
    axios
      .post("/user/removeAccount", { email: this.state.remove.email })
      .then(res => {
        if (res.data.success) {
          this.setState(
            {
              error: {},
              remove: {},
              loading: false
            },
            this.fetchUsers
          );
        } else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  addUser = email => {
    this.setState({ loading: true });
    axios
      .post("/user/addAccount", {
        email: email || this.state.email
      })
      .then(res => {
        if (res.data.success)
          this.setState({ loading: false, add: false }, this.fetchUsers);
        else this.setState({ loading: false, error: res.data.error });
      })
      .catch(err => this.setState({ loading: false }));
  };

  editUser = () => {
    const { edit } = this.state;
    this.setState({ loading: true });
    axios
      .post("/user/settings", {
        id: edit._id,
        ...edit,
        permissions: JSON.stringify([...edit.permissions])
      })
      .then(res => {
        if (res.data.success)
          this.setState({ loading: false, edit: {} }, this.fetchUsers);
        else this.setState({ loading: false, error: res.data.error });
      })
      .catch(err => this.setState({ loading: false }));
  };

  toggleAdmin = () => {
    const { edit } = this.state;
    this.setState({ loading: true });
    axios
      .post("/account/toggleAdmin", {
        userID: edit._id
      })
      .then(res => {
        if (res.data.success)
          this.setState({ loading: false, edit: {} }, this.fetchUsers);
        else this.setState({ loading: false, error: res.data.error });
      })
      .catch(err => this.setState({ loading: false }));
  };

  getPermissions(permissions) {
    const { account } = this.state;
    if (permissions instanceof Map)
      return (
        permissions.get(account._id) && permissions.get(account._id).allowed
      );
  }

  getStatuses(statuses) {
    let options = this.state.statuses;
    let temp = [];
    if (statuses)
      options.map(
        option => statuses.includes(option.value) && temp.push(option)
      );
    return temp;
  }

  filterPermissionOptions(permissions) {
    const { account } = this.state;
    let temp = permissionOptions;
    let cur = permissions instanceof Map ? permissions.get(account._id) : null;
    if (cur) {
      cur.allowed.forEach((allow, index) => {
        temp = temp.filter(permission => {
          return permission.value !== allow;
        });
      });
    }
    return temp;
  }

  filterStatusOptions(statuses) {
    let temp = this.state.statuses;
    if (statuses)
      temp = temp.filter(status => !statuses.includes(status.value));
    return temp;
  }

  setPermissions(permissions, newPermission, index) {
    const { account } = this.state;
    let temp = permissions instanceof Map ? permissions : new Map();
    let cur = temp.get(account._id);
    if (!cur) {
      temp.set(account._id, { allowed: [], statuses: [] });
      cur = { allowed: [], statuses: [] };
    }
    if (index !== undefined) {
      cur.allowed.splice(index, 1);
    } else cur.allowed.push(newPermission);
    temp.set(account._id, cur);
    let tempEdit = this.state.edit;
    tempEdit.permissions = temp;
    this.setState({ edit: tempEdit });
  }

  setStatuses(statuses, newStatus, index) {
    let temp = statuses;
    if (index !== undefined) {
      temp.splice(index, 1);
    } else temp.push(newStatus);
    let tempEdit = this.state.edit;
    tempEdit.statuses = temp;
    this.setState({ edit: tempEdit });
  }

  componentDidMount() {
    this.fetchAccounts();
    this.fetchProcesses();
  }
  render() {
    const {
      accounts,
      users,
      remove,
      add,
      email,
      edit,
      processes,
      statuses,
      loading,
      account
    } = this.state;
    const mobile = this.props.windowWidth < 800;

    const columns = [
      {
        label: "Name",
        render: row => (
          <Text size="1.5vw">
            {row.firstName ? row.firstName + " " + row.lastName : " "}
          </Text>
        ),
        width: "20vw"
      },

      {
        label: "email",
        render: row => <Text size="1.5vw">{row.email}</Text>,
        width: "20vw"
      }
    ];

    return (
      <NavBar history={this.props.history} loading={loading}>
        <Section backgroundColor={"rgba(0,0,0,.3)"} height="92vh">
          <InfoBox margin="2% auto 1% auto" width="90vw">
            <Button
              margin="auto 2% 2% auto"
              onClick={() => this.setState({ add: true })}
            >
              Invite User
            </Button>
            <Table
              onSelect={row => {
                if (!(row.permissions instanceof Map))
                  row.permissions = new Map(Object.entries(row.permissions));
                this.setState({ edit: row });
              }}
              data={users}
              columns={columns}
            />
          </InfoBox>
          <Modal
            open={remove._id}
            onClose={() => this.setState({ remove: {} })}
          >
            <Text>
              Are you sure you want to remove{" "}
              {remove.firstName
                ? remove.firstName + " " + remove.lastName
                : remove.email}
              from your organization?
            </Text>
            <Button margin={"2%"} onClick={this.removeUser}>
              Remove User
            </Button>
          </Modal>
          <Modal open={add} onClose={() => this.setState({ add: false })}>
            <Text>Invite user to {account.name}</Text>
            <Input
              width="45%"
              name="email"
              label="Email"
              value={email}
              fontSize={mobile ? "4vw" : "1vw"}
              onChange={v => this.setState({ email: v })}
            />
            <Button
              margin="2% auto auto auto"
              onClick={() => this.addUser(email)}
            >
              Invite User
            </Button>
          </Modal>
          <Modal open={edit._id} onClose={() => this.setState({ edit: {} })}>
            <Clickable
              onClick={() => this.setState({ remove: edit, edit: {} })}
              margin="auto 1% auto auto"
            >
              <FontAwesomeIcon
                style={{ color: Theme.RED, width: "auto", height: "4vh" }}
                icon={faTimes}
              />
            </Clickable>
            <Text size={mobile ? "7vw" : "3vw"}>
              {edit.firstName
                ? edit.firstName + " " + edit.lastName
                : edit.email}
            </Text>
            <Text margin="2% auto auto auto" size={mobile ? "5vw" : "1.5vw"}>
              User Permissions
            </Text>
            <MultiSelectList
              options={this.filterPermissionOptions(edit.permissions)}
              selected={this.getPermissions(edit.permissions)}
              onSelect={v => {
                this.setPermissions(edit.permissions, v.target.value);
              }}
              onRemove={(index, v) =>
                this.setPermissions(edit.permissions, v, index)
              }
            />
            <Text margin="4% auto 2% auto" size={mobile ? "5vw" : "1.5vw"}>
              Statuses They Can Move Applicants To
            </Text>
            <Input
              name="process"
              label="Selected Process"
              options={processes}
              margin="auto auto 1% auto"
              fontSize={mobile ? "4vw" : "1vw"}
              width={mobile ? "80vw" : "40vw"}
              onChange={v =>
                this.setState(
                  {
                    processID: v.target.value
                  },
                  this.fetchStatuses
                )
              }
            />
            <MultiSelectList
              options={this.filterStatusOptions(edit.statuses)}
              selected={this.getStatuses(edit.statuses)}
              onSelect={v => {
                this.setStatuses(edit.statuses, v.target.value);
              }}
              onRemove={(index, v) => this.setStatuses(edit.statuses, v, index)}
            />
            <Button onClick={this.editUser} margin={"2%"}>
              Save Changes
            </Button>
            <Row width="100%">
              <Button margin="2%" width="46%" onClick={this.toggleAdmin}>
                {edit.admin ? "Remove Admin" : "Grant Admin"}
              </Button>
              <Button
                margin={"2%"}
                width="46%"
                onClick={() => this.addUser(edit.email)}
              >
                Resend Invite Email
              </Button>
            </Row>
          </Modal>
        </Section>
      </NavBar>
    );
  }
}

export default windowSize(Home);

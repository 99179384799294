import React, { Component, Window } from "react";
import styled, { keyframes, css } from "styled-components";
import Circular from "../assets/logos/Secondary_Logo.png";
import {
  Dropdown,
  NavButton,
  Clickable,
  Text,
  Row,
  NavMenu,
  ProfileButton,
  Drawer,
  Modal,
} from "./";
import Theme from "./Theme.js";
import { fadeInDown, rollIn } from "react-animations";
import Page_Background from "../assets/Page_Background.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTimes,
  faBars,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
const routes = [
  {
    label: "Sites",
    menu: [
      { label: "Site Info", route: "/sites", permission: "Sites" },
      { label: "Feeds", route: "/feeds", permission: "Feeds" },
    ],
    route: "/sites",
  },
  {
    label: "Recruitment",
    menu: [
      { label: "Applicants", route: "/applicants", permission: "Applicants" },
      { label: "Positions", route: "/positions", permission: "Positions" },
      { label: "Processes", route: "/statuses", permission: "Statuses" },
      { label: "Forms", route: "/forms", permission: "Forms" },
    ],
    route: "/forms",
  },
  {
    label: "Admin",
    menu: [{ label: "Users", route: "/users", permission: "Users" }],
  },
  {
    label: "Super",
    menu: [{ label: "Account", route: "/accounts" }],
    menu: [{ label: "Billing", route: "/billing" }],
    super: true,
  },
];

class NavBar extends Component {
  state = {
    initialize: this.props.history.location.pathname === "/home",
    allowed: [],
  };

  fetchCurrent = () => {
    this.setState({ loading: true });
    axios.get("/user/current").then(async (res) => {
      if (res.data.success) {
        cookie.set("token", res.data.token);
        this.setState({
          superAdmin: res.data.user.superAdmin,
          account: res.data.account,
          admin: res.data.user.admin,
          loading: false,
          allowed: res.data.allowed,
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  filterMenu(menu) {
    if (!this.state.superAdmin && this.state.allowed !== "admin")
      menu = menu.filter(
        (route) =>
          !route.permission || this.state.allowed.includes(route.permission)
      );
    return menu;
  }

  componentDidMount() {
    this.fetchCurrent();
  }

  render() {
    const {
      initialize,
      allowed,
      drawer,
      account,
      superAdmin,
      admin,
    } = this.state;
    const { notify, onConfirm } = this.props;
    const mobile = this.props.windowWidth < 600;
    const tiny = this.props.windowWidth < 400;
    if (initialize)
      setTimeout(() => this.setState({ initialize: false }), 1500);
    return (
      <div>
        <div
          style={{
            display: "flex",
            height: "8vh",
            flexDirection: "row",
            width: "100vw",
            backgroundColor: Theme.BLACK,
            boxShadow: `0 0 10px ${Theme.BLUE}`,
            zIndex: 99,
            position: "fixed",
          }}
        >
          <NavButton
            disableHover={true}
            animation={initialize && css`1s ${keyframes`${rollIn}`}`}
            onClick={() => this.props.history.push("/")}
          >
            <Row noWrap>
              <img
                src={Circular}
                style={{
                  marginTop: "-1vh",
                  height: mobile ? "7vh" : "10vh",
                }}
              />
              <Text
                hidden={mobile}
                color="white"
                size="3vh"
                margin="auto 0 2vh auto"
              >
                Aviary
              </Text>
            </Row>
          </NavButton>
          <Clickable
            margin="auto 1vw 1vh 2vw"
            onClick={() => this.props.history.push("/changeCompany")}
          >
            <Text color={Theme.BLUE} size="2vh">
              {account !== undefined ? account.name : "No Account Selected"}
            </Text>
          </Clickable>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "auto",
              marginLeft: "auto",
            }}
          >
            {!mobile &&
              routes.map((route, index) => {
                if (route.super && !superAdmin) return;
                else if (route.admin && !admin) return;
                else if (route.menu)
                  return this.filterMenu(route.menu).length > 0 ? (
                    <Dropdown
                      key={index}
                      history={this.props.history}
                      label={route.label}
                      options={this.filterMenu(route.menu)}
                      mobile={mobile}
                      animation={
                        initialize &&
                        css` 1s
                        ${keyframes`${fadeInDown}`}
                      `
                      }
                    />
                  ) : null;
                else {
                  if (allowed.includes(route.route.split(1)[1])) return;
                  else
                    return (
                      <NavButton
                        key={route.label + index}
                        disableHover={initialize}
                        animation={
                          initialize &&
                          css` 1s
                      ${keyframes`${fadeInDown}`}
                    `
                        }
                        onClick={() => {
                          this.setState({ open: false });
                          this.props.history.push(route.route);
                        }}
                        mobile={tiny}
                      >
                        {route.label}
                      </NavButton>
                    );
                }
              })}
          </div>
          <NavMenu
            filter={(menu) => this.filterMenu(menu)}
            routes={routes}
            history={this.props.history}
          />
          <ProfileButton
            history={this.props.history}
            size="3vw"
            margin="auto 0 auto 0"
          />
        </div>
        <div
          style={{
            overflow: "hidden",
          }}
        >
          <img
            src={Page_Background}
            style={{
              position: "fixed",
              height: "100vh",
              minWidth: "100vw",
              zIndex: -1,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(53,53,90,.0)",
            justifyContent: "flex-start",
            minHeight: "92vh",
            paddingTop: "8vh",
          }}
        >
          {this.props.children}
          <div
            style={{
              display: this.props.loading ? "flex" : "none",
              position: "absolute",
              zIndex: 98,
              height: "100vh",
              width: "100vw",
              backgroundColor: "rgba(0,0,0,.4)",
            }}
          />
          <FontAwesomeIcon
            spin
            style={{
              height: "10vw",
              width: "10vw",
              left: "45vw",
              top: "45vh",
              color: "white",
              zIndex: 99,
              display: this.props.loading ? "flex" : "none",
              position: "absolute",
            }}
            icon={faSpinner}
          />
        </div>
        <Modal open={!!notify} onClose={onConfirm}>
          <Text>{notify}</Text>
        </Modal>
      </div>
    );
  }
}

export default windowSize(NavBar);

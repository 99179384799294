import React, { Component } from "react";
import windowSize from "react-window-size";
import styled from "styled-components";
import {
  Column,
  Text,
  Button,
  Input,
  InfoBox,
  Clickable,
  Row,
  Modal,
} from "./index.js";
import moment from "moment";
import Theme from "./Theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

class Notification extends Component {
  state = {
    open: false,
  };

  render() {
    const {
      label,
      onClose,
      height,
      width,
      notes = [],
      onAdd,
      onRemove,
      onNoteInput,
      text,
      forceNote,
    } = this.props;
    const { open } = this.state;
    const mobile = this.props.windowWidth < 700;

    return (
      <InfoBox margin="0 auto auto auto">
        <Clickable
          width={mobile ? "4vw" : "1vw"}
          height={mobile ? "4vw" : "1vw"}
          margin="0 0 -5% auto"
          onClick={() => this.setState({ open: true })}
        >
          <FontAwesomeIcon
            style={{ height: "100%", width: "100%" }}
            icon={faPlus}
          />
        </Clickable>
        <Text size="200%">Notes</Text>
        <Column
          height={height || "60vh"}
          overflow="scroll"
          backgroundColor={Theme.BLUE}
          width={width || (mobile ? "95vw" : "20vw")}
        >
          {notes.map((note) => (
            <InfoBox width="90%" margin={"1% auto 1% auto"}>
              <Row>
                <Text size={mobile ? "4vw" : "1vw"}>{note.user}</Text>
                <Text margin="0 0 0 auto" size={mobile ? "3vw" : ".8vw"}>
                  {moment(note.timestamp).format("L - LT ")}
                </Text>
              </Row>

              <Text size={mobile ? "3vw" : "1vw"}>{note.text}</Text>
            </InfoBox>
          ))}
        </Column>
        <Modal
          open={open || forceNote}
          onClose={() => this.setState({ open: false })}
        >
          <Text size={mobile ? "4vw" : "2vw"}>Write your note here</Text>
          <Input
            height={mobile ? "90vh" : "50vh"}
            multiline
            name="text"
            value={text}
            onChange={onNoteInput}
          />
          <Row>
            <Button
              color={Theme.LIGHTER_GREY}
              margin="1% auto auto auto"
              width="30vw"
              onClick={() => {
                this.setState({ open: false });
                this.props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              width="30vw"
              margin="1% auto auto auto"
              onClick={() => {
                this.setState({ open: false });
                onAdd();
              }}
            >
              Add Note
            </Button>
          </Row>
        </Modal>
      </InfoBox>
    );
  }
}
export default windowSize(Notification);

import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Input,
  Button,
  Modal,
  Clickable,
  Table
} from "../../globalComponents";
import Theme from "../../globalComponents/Theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

class Home extends React.Component {
  state = {
    accounts: [],
    accounts: [],
    edit: {},
    name: "",
    add: false
  };

  fetchAccounts = () => {
    this.setState({ loading: true });
    axios.get("/accounts").then(res => {
      if (res.data.success) {
        this.setState({
          accounts: res.data.accounts,
          error: {},
          loading: false
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.edit("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  addAccount = () => {
    this.setState({ loading: true });
    axios
      .post("/account/", {
        name: this.state.name,
        dbName: this.state.dbName
      })
      .then(res => {
        if (res.data.success) {
          this.setState(
            {
              error: {},
              add: false,
              name: "",
              dbName: "",
              loading: false
            },
            this.fetchAccounts
          );
        } else if (res.data.error === "Not Authorized") {
          cookie.edit("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  editAccount = account => {
    this.setState({ loading: true });
    axios
      .post("/account", {
        id: account._id,
        name: account.name,
        dbName: account.dbName
      })
      .then(res => {
        if (res.data.success) {
          this.setState(
            {
              error: {},
              edit: {},
              name: "",
              dbName: "",
              loading: false
            },
            this.fetchAccounts
          );
        } else if (res.data.error === "Not Authorized") {
          cookie.edit("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  componentDidMount() {
    this.fetchAccounts();
  }
  render() {
    const { accounts, edit, add, email, name, dbName, loading } = this.state;
    const mobile = this.props.windowWidth < 800;

    const columns = [
      {
        label: "Name",
        render: row => <Text size="1.5vw">{row.name}</Text>,
        width: "20vw"
      },
      {
        label: "Database",
        render: row => <Text size="1.5vw">{row.dbName}</Text>,
        width: "20vw"
      },
      {
        label: "Status",
        render: row => <Text size="1.5vw">{row.status}</Text>,
        width: "20vw"
      },
      {
        label: "Actions",
        width: "10vw",
        render: row => (
          <div
            style={{
              display: "flex",
              flexDirection: "row"
            }}
          >
            <Clickable
              onClick={() => this.setState({ edit: row })}
              margin="auto 5% auto auto"
            >
              <FontAwesomeIcon
                style={{ color: "blue" }}
                size="lg"
                icon={faEdit}
              />
            </Clickable>
          </div>
        )
      }
    ];

    return (
      <NavBar history={this.props.history} loading={loading}>
        <Section backgroundColor={"rgba(0,0,0,.3)"} height="92vh">
          <InfoBox margin="2% auto 1% auto" width="90vw">
            <Button
              margin="auto 2% 2% auto"
              onClick={() => this.setState({ add: true })}
            >
              Create Account
            </Button>
            <Table data={accounts} columns={columns} />
          </InfoBox>
          <Modal open={edit._id} onClose={() => this.setState({ edit: {} })}>
            <Input
              width="45%"
              name="name"
              label="Name"
              value={edit.name}
              fontSize={mobile ? "4vw" : "1vw"}
              onChange={v => {
                let temp = edit;
                temp.name = v;
                this.setState({ edit: temp });
              }}
            />
            <Input
              width="45%"
              name="dbName"
              label="Database Name"
              value={edit.dbName}
              fontSize={mobile ? "4vw" : "1vw"}
              onChange={v => {
                let temp = edit;
                temp.dbName = v;
                this.setState({ edit: temp });
              }}
            />
            <Button
              margin="2% auto auto auto"
              onClick={() => this.editAccount(edit)}
            >
              Edit Account
            </Button>
          </Modal>
          <Modal
            open={add}
            onClose={() => this.setState({ add: false, name: "" })}
          >
            <Input
              width="45%"
              name="name"
              label="Name"
              value={name}
              fontSize={mobile ? "4vw" : "1vw"}
              onChange={v => this.setState({ name: v })}
            />
            <Input
              width="45%"
              name="dbName"
              label="Database Name"
              value={dbName}
              fontSize={mobile ? "4vw" : "1vw"}
              onChange={v => this.setState({ dbName: v })}
            />
            <Button margin="2% auto auto auto" onClick={this.addAccount}>
              Create Account
            </Button>
          </Modal>
        </Section>
      </NavBar>
    );
  }
}

export default windowSize(Home);

import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  InfoBox,
  Input,
  Image,
  Row,
  Column,
  Button,
  Notification,
  Text,
} from "../../globalComponents";
import Logo from "../../assets/logos/Main_Logo.png";
import Page_Background from "../../assets/Page_Background.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

class Login extends React.Component {
  state = {
    newPassword: "",
    code: "",
    confirmPassword: "",
    error: { name: "", text: "" },
  };
  change = () => {
    const { code, newPassword, confirmPassword } = this.state;
    this.setState({ loading: true });
    if (!code)
      this.setState({
        loading: false,
        error: { name: "code", text: "must provide reset code" },
      });
    else if (!newPassword)
      this.setState({
        loading: false,
        error: { name: "newPassword", text: "Required" },
      });
    else if (!confirmPassword)
      this.setState({
        loading: false,
        error: { name: "confirmPassword", text: "Required" },
      });
    else if (newPassword !== confirmPassword)
      this.setState({
        loading: false,
        error: { name: "general", text: "Passwords must match" },
      });
    else
      axios
        .post("/user/password", {
          code: code,
          password: newPassword,
        })
        .then((res) => {
          if (res.data.success) {
            this.setState(
              { error: { name: "", text: "", loading: false, confirm: true } },
              this.props.history.push("/login")
            );
          } else
            this.setState({
              error: { name: "general", text: res.data.error },
              loading: false,
            });
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            error: { name: "general", text: "Server Error Try Again" },
            loading: false,
          });
        });
  };
  render() {
    const {
      code,
      newPassword,
      confirmPassword,
      error,
      loading,
      confirm,
    } = this.state;
    const mobile = this.props.windowWidth < 1000;

    return (
      <Column>
        <img
          src={Page_Background}
          style={{
            position: "fixed",
            height: "100vh",
            minWidth: "100vw",
            zIndex: -1,
          }}
        />
        <InfoBox
          padding="0"
          margin="10vh auto auto auto"
          borderRadius="20px"
          maxWidth="600px"
          width={mobile ? "75vw" : "45vw"}
        >
          <Column backgroundColor="rgba(0,0,0,.1)" width="100%">
            <Button
              position="relative"
              margin=".5%"
              width="5%"
              onClick={() => this.props.history.push("/login")}
            >
              <FontAwesomeIcon
                style={{ margin: "auto 5% auto auto" }}
                icon={faArrowLeft}
              />
              Back
            </Button>
            <Image
              margin="-5% auto auto auto"
              src={Logo}
              height={mobile ? "20vw" : "10vw"}
            />
            <Text size="120%" margin="-5% auto 2% auto">
              Aviary
            </Text>
          </Column>
          <Text
            margin="2% auto 2% auto"
            size={mobile ? "3vw" : "1.5vw"}
            textAlign="center"
          >
            Check your email for a reset code
          </Text>
          <Input
            label="Reset Code"
            value={code}
            error={error.name === "code" ? error.text : ""}
            height="6vh"
            width={"70%"}
            onChange={(v) => this.setState({ code: v })}
          />{" "}
          <Input
            label="New Password"
            value={newPassword}
            error={error.name === "newPassword" ? error.text : ""}
            height="6vh"
            type="password"
            width={"70%"}
            onChange={(v) => this.setState({ newPassword: v })}
          />
          <Input
            label="Confirm Password"
            value={confirmPassword}
            error={error.name === "confirmPassword" ? error.text : ""}
            height="6vh"
            type="password"
            width={"70%"}
            onChange={(v) => this.setState({ confirmPassword: v })}
          />
          <Text margin="auto" color="red">
            {error.name == "general" && error.text}
          </Text>
          <Button
            width={"70%"}
            loading={loading}
            margin="3% auto 3% auto"
            onClick={this.change}
          >
            Update Password
          </Button>
        </InfoBox>
        <Notification
          open={confirm}
          message="Password reset successfully"
          title="Success"
          onClose={() => this.props.history.push("/login")}
        />
      </Column>
    );
  }
}
export default windowSize(Login);

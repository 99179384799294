import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Column,
  Input,
  Button,
  Row,
  Notification,
  Clickable,
  Upload,
  Checkbox,
  Modal,
  Table,
  Notes,
} from "../../globalComponents";
import ApplicantForm from "./applicantForm.js";
import Theme from "../../globalComponents/Theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

class Home extends React.Component {
  state = {
    accounts: [],
    applicant: {},
    positions: [],
    position: {},
    status: {},
    applicants: [],
    forms: [],
    form: {},
    files: [],
    fields: [],
    statuses: [],
    statusOptions: [],
    curStatus: {},
    curApp: {},
    limit: 50,
    page: 1,
    notify: {},
    search: "",
    searchIndex: 0,
    appOptions: [],
    sort: "firstName",
    direction: 1,
    showArchived: false,
    columns: [
      {
        label: "First",
        render: (row) => <Text size="100%">{row.firstName}</Text>,
      },
      {
        label: "Last",
        render: (row) => <Text size="100%">{row.lastName}</Text>,
      },
      {
        label: "Email",
        render: (row) => <Text size="100%">{row.email}</Text>,
      },
    ],
    sortOptions: [
      { label: "First", name: "firstName", type: "standard" },
      { label: "Last", name: "lastName", type: "standard" },
      { label: "Email", name: "email", type: "standard" },
    ],
    searchOptions: [
      {
        label: "Name",
        name: "name",
        type: "standard",
      },
      {
        label: "Email",
        name: "email",
        type: "standard",
      },
    ],
  };
  fetchApplicants = () => {
    this.setState({ loading: true });
    axios
      .get("/applicants", {
        params: {
          positionID: this.state.positionID,
          statusID: this.state.statusID,
          sort: this.state.sort,
          direction: this.state.direction,
          showArchived: this.state.showArchived ? 1 : "",
          search: this.state.search,
          searchBy: this.state.searchOptions[this.state.searchIndex].name,
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            applicants: res.data.applicants,
            error: {},
            loading: false,
          });
        } else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  fetchPositions = () => {
    this.setState({ loading: true });
    axios.get("/positions", { params: { noProcess: true } }).then((res) => {
      if (res.data.success) {
        let temp = [{ label: "All", value: 0 }];
        res.data.positions.map((position, index) =>
          temp.push({ label: position.label, value: index + 1, position })
        );
        this.setState(
          {
            positions: temp,
            positionID:
              temp[0] && temp[0].position ? temp[0].position._id : null,
            error: {},
            loading: false,
          },
          this.fetchStatuses
        );
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  fetchInfoColumns = () => {
    this.setState({ loading: true });
    axios
      .get("/form", { params: { name: "Applicant Signup Form" } })
      .then((res) => {
        if (res.data.success) {
          let temp = this.state.columns;
          let options = this.state.sortOptions;
          res.data.form &&
            res.data.form.fields.map((field, index) => {
              if (field.tableView)
                temp.push({
                  label: field.label,
                  render: (row) => (
                    <Text size="100%">{row.info && row.info[field._id]}</Text>
                  ),
                });
              options.push({
                label: field.label,
                name: field._id,
                type: "info",
              });
            });

          temp.push({
            label: "Status",
            render: (row) => (
              <Text size="100%">
                {row.applications[0] && row.applications[0].status.label}
              </Text>
            ),
          });
          options.push({ label: "Status", name: "status", type: "status" });
          this.setState({
            columns: temp,
            sortOptions: options,
          });
        } else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  fetchApplicant = () => {
    axios
      .get(`/applicant/current`, {
        params: { id: this.state.selected._id },
      })
      .then((res) => {
        if (res.data.success) {
          let curApp = {};
          let appOptions = [{ label: "Select an Application to View" }];
          res.data.applicant.applications.map((app, index) => {
            if (app.position._id.toString() == this.state.positionID)
              curApp = app;

            this.state.positions.map((position) => {
              if (position.label === "All") return;
              else if (position.position._id == app.position._id.toString())
                appOptions.push({
                  label: app.position.label,
                  value: position._id,
                  app,
                  position,
                });
            });
          });
          this.setState(
            {
              applicant: res.data.applicant,
              curApp,
              appOptions,
              loading: false,
            },
            this.fetchStatuses
          );
          if (!curApp.position)
            this.setState(
              {
                position: res.data.applicant.applications[0]
                  ? res.data.applicant.applications[0].position
                  : {},
                curApp: res.data.applicant.applications[0],
                positionID: res.data.applicant.applications[0]
                  ? res.data.applicant.applications[0].position._id
                  : null,
              },
              this.fetchStatuses
            );
        } else {
          if (res.data.error === "Not Authorized") {
            if (cookie.get("applicantToken")) {
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${cookie.get("applicantToken")}`;
              setTimeout(this.fetchApplicant, 40);
            }
            this.setState({ error: "Unable to fetch Applicant" });
          } else if (res.data.error === "No applicant found") {
            this.setState({
              error: { general: "Please Login" },
              signedIn: false,
              loading: false,
            });
          }
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  getForms = (applicant) => {
    this.setState({ loading: true });
    axios
      .get("/applicant/forms", { params: { id: applicant._id } })
      .then((res) => {
        if (res.data.success) {
          if (
            !res.data.applicant.applications ||
            res.data.applicant.applications.length == 0
          ) {
            this.setState({
              notification: "This applicant hasn't applied for a position yet",
            });
          }
          let temp = [];
          res.data.forms.map((form, index) =>
            temp.push({
              label: `${form.form.name} (Version: ${
                form.answers.formVersion || form.form.version
              })`,
              value: index,
              form: form.form,
              answers: form.answers,
            })
          );

          let tempFields = [];
          if (
            temp.length > 0 &&
            temp[0].form.version !== temp[0].answers.formVersion
          )
            temp[0].form.versions.map((item) => {
              if (item.versionId === temp[0].answers.formVersion)
                tempFields = item.fields;
            });
          else if (temp.length > 0) tempFields = temp[0].form.fields;

          this.setState(
            {
              error: {},
              forms:
                temp.length > 0
                  ? temp
                  : [{ label: "No Forms Submitted", value: null }],
              form: temp[0] ? temp[0].form : {},
              fields: tempFields,
              selected: res.data.applicant,
              values: temp[0] ? temp[0].answers.values : [],
              answerID: temp[0] ? temp[0].answers._id : null,
              loading: false,
            },
            this.fetchApplicant
          );
        } else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  fetchStatuses = () => {
    this.setState({ loading: true });
    if (!(this.state.position && this.state.position._id))
      this.setState(
        { statuses: [{ label: "Select a position first" }] },
        this.fetchApplicants
      );
    else
      axios
        .get("/process/statuses", {
          params: { processID: this.state.position.process },
        })
        .then((res) => {
          if (res.data.success) {
            var temp = [{ label: "All", value: "" }];
            res.data.statuses.map((status, index) => {
              temp.push({ label: status.label, value: status._id, status });
            });
            this.fetchChangeableStatuses(temp);
            this.setState(
              {
                statuses: temp,
                error: {},
                loading: false,
              },
              this.fetchApplicants
            );
          } else if (res.data.error === "Not Authorized") {
            cookie.remove("token");
            window.location.reload();
          } else this.setState({ error: res.data.error, loading: false });
        });
  };

  fetchChangeableStatuses = (statuses) => {
    this.setState({ loading: true });
    axios.get("/user/current").then((res) => {
      if (res.data.success) {
        let temp = [];
        if (res.data.user.superAdmin || res.data.user.admin)
          temp = statuses.filter((status) => status.label !== "All");
        else
          statuses.map((status, index) => {
            if (res.data.user.statuses.includes(status.value))
              temp.push(status);
          });
        this.setState(
          {
            statusOptions: temp,
            error: {},
            loading: false,
          },
          this.fetchApplicants
        );
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  changeStatus = (id) => {
    const { selected } = this.state;
    this.setState({ loading: true });
    axios
      .post("/applicant/updateStatus", {
        applicantID: selected._id,
        positionID: this.state.positionID,
        statusID: id,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ forceNote: null });
          this.fetchApplicant();
        }
      });
  };

  //Form Edit/View Functions

  deleteAnswer = () => {
    this.setState({ loading: true });
    axios
      .post("/applicant/deleteAnswer", {
        applicantID: this.state.selected._id,
        answerID: this.state.answerID,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              selected: res.data.applicant,
              error: {},
              loading: false,
            },
            () => this.getForms(res.data.applicant)
          );
        } else if (res.data.error === "Not Authorized") {
          cookie.remove("token");
          window.location.reload();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  getForm = (index) => {
    this.setState({ loading: true });
    axios.get("/form", { params: { id: this.state.form } }).then((res) => {
      if (res.data.success) {
        this.setState({
          error: {},
          form: res.data.form,
          fields: res.data.form.fields,
          loading: false,
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  submitForm = async () => {
    this.uploadFiles(this.state.values).then((values) => {
      if (values)
        axios
          .post("/applicant/anonymous", {
            id: this.state.form._id,
            values: values,
            version: this.state.form.version,
          })
          .then((res) => {
            if (res.data.success)
              this.setState({
                error: {},
                feed: res.data.feed,
                loading: false,
              });
            else if (res.data.error === "Not Authorized") {
              cookie.remove("token");
              window.location.reload();
            } else this.setState({ error: res.data.error, loading: false });
          });
    });
  };

  changeValue = (id, value, select) => {
    var temp = this.state.values;
    if (select) temp[id] = value.target.value;
    else temp[id] = value;
    this.setState({ values: temp });
  };

  //Image functions
  displayFiles = (name, newFiles) => {
    const { files } = this.state;
    if (!newFiles) return [];
    var reader = new FileReader();
    const readFile = (index) => {
      if (index >= newFiles.length) return;
      var file = newFiles[index];
      reader.onloadend = (e) => {
        var temp = files;
        temp[name]
          ? temp[name].push(e.target.result)
          : (temp[name] = [e.target.result]);
        this.setState({ files: temp }, () => readFile(index + 1));
      };
      reader.readAsDataURL(file);
    };
    readFile(0);
  };

  uploadFiles = (vals) => {
    return new Promise((resolve, reject) => {
      const { feed, values, fields } = this.state;
      var newVals = values;
      var images = [];

      fields.map((field, index) => {
        if (!!values[field._id].url) {
          values[field._id].index = index;
          images.push(values[field._id]);
        } else if (values[field._id].length > 0) {
          values[field._id].map((image) => {
            image.id = field._id;
            images.push(image);
          });
        }
      });
      if (images.length < 1) resolve(vals);
      var count = 0;
      images.map((image, index) => {
        if (!image.signed) {
          count++;
          if (count === images.length) resolve(newVals);
          return;
        } else {
          var instance = axios.create();
          delete instance.defaults.headers.common["Authorization"];
          instance
            .put(image.signed, image.file, {
              headers: { "Content-Type": image.file.type },
            })
            .then((res) => {
              if ((res.statusText = "OK")) {
                if (typeof newVals[image.id] === "array")
                  newVals[image.id].push(image.url);
                else newVals[image.id] = [image.url];
                count++;
                if (count === images.length) {
                  resolve(newVals);
                }
              } else {
                reject("Error uploading files for storage");
              }
            });
        }
      });
    });
  };

  handleImages = (files, fieldID) => {
    const { error, values, form } = this.state;
    const signFile = (index) => {
      if (index >= files.length) {
        this.displayFiles(fieldID, files);
      } else
        axios
          .post("/form/file/upload", {
            fileName: fieldID + "_name_" + files[index].fieldID,
            fileType: files[index].type,
            file: files[index],
            formID: form._id,
          })
          .then((res) => {
            if (!res.data.success) {
              var temp = error;
              temp[index] = "Trouble uploading image try again";
              this.setState({ error: temp });
            } else {
              var newVals = values;
              newVals[fieldID]
                ? newVals[fieldID].push({
                    signed: res.data.aws_data.returnData.signedRequest,
                    url: res.data.aws_data.returnData.url,
                    file: files[index],
                  })
                : (newVals[fieldID] = [
                    {
                      signed: res.data.aws_data.returnData.signedRequest,
                      url: res.data.aws_data.returnData.url,
                      file: files[index],
                    },
                  ]);
              this.setState({ values: newVals }, () => {
                signFile(index + 1);
              });
            }
          })
          .catch((err) => {
            console.error(err);
            var temp = this.state.error;
            temp[index] = "Trouble uploading image try again";
            this.setState({ error: temp });
          });
    };
    signFile(0);
  };
  deleteImage = (index, fieldID) => {
    var temp = this.state.files;
    var newVals = this.state.values;
    temp[fieldID].splice(index, 1);
    newVals[fieldID].splice(index, 1);
    this.setState({ values: newVals, files: temp });
  };

  //Notes Functions
  addNote = async () => {
    axios
      .post("/applicant/note", {
        text: this.state.noteText,
        applicantID: this.state.selected._id,
      })
      .then((res) => {
        if (res.data.success)
          this.setState(
            {
              selected: res.data.applicant,
              noteText: "",
              loading: false,
            },
            () => {
              if (this.state.forceNote === "archive") {
                this.archive();
              } else if (this.state.forceNote) {
                this.changeStatus(this.state.forceNote);
              }
            }
          );
        else this.setState({ error: res.data.error, loading: false });
      });
  };

  editNote = async (note) => {
    axios
      .post("/applicant/note", {
        id: note._id,
        text: this.state.noteText,
        applicantID: this.state.selected._id,
      })
      .then((res) => {
        if (res.data.success)
          this.setState({
            selected: res.data.applicant,
            noteText: "",
            loading: false,
          });
        else this.setState({ error: res.data.error, loading: false });
      });
  };

  removeNote = async (note) => {
    axios
      .post("/applicant/removeNote", {
        id: note._id,
        text: this.state.noteText,
        applicantID: this.state.selected._id,
      })
      .then((res) => {
        if (res.data.success)
          this.setState({
            selected: res.data.applicant,
            loading: false,
          });
        else this.setState({ error: res.data.error, loading: false });
      });
  };

  //Application edit functions

  archive = () => {
    const { selected, curApp } = this.state;
    this.setState({ loading: true });
    axios
      .post("/applicant/archiveApplication", {
        id: selected._id,
        positionID: curApp.position._id,
        unArchive: curApp.archived,
      })
      .then((res) => {
        if (res.data.success)
          this.setState(
            {
              selected: res.data.applicant,
              loading: false,
              forceNote: null,
            },
            this.fetchApplicant
          );
        else this.setState({ error: res.data.error, loading: false });
      });
  };

  getPage(rows) {
    const { page, limit } = this.state;
    if (rows.length > 0) {
      let temp = JSON.parse(JSON.stringify(rows));
      temp.splice(0, limit * (page - 1));
      if (limit * page < temp.length) temp.splice(limit, temp.length - 1);
      return temp;
    } else return [];
  }

  queueFetch = () => {
    console.log("here");
    let tempTimer = this.state.timer;
    if (tempTimer) {
      clearTimeout(tempTimer);
      tempTimer = setTimeout(this.fetchApplicants, 600);
    } else tempTimer = setTimeout(this.fetchApplicants, 600);
    this.setState({ timer: tempTimer });
  };

  componentDidMount() {
    this.fetchInfoColumns();
    this.fetchPositions();
  }

  render() {
    const {
      accounts,
      applicant,
      applicants,
      notify,
      showNotes,
      forms,
      form,
      files,
      values,
      fields,
      selected,
      error,
      positions,
      statuses,
      statusOptions,
      curApp,
      appOptions,
      position,
      positionFilter,
      statusID,
      status,
      forceNote,
      columns,
      positionID,
      page,
      limit,
      noteText,
      options,
      sortOptions,
      search,
      searchOptions,
      sortBy,
      direction,
      showArchived,
      loading,
    } = this.state;
    const mobile = this.props.windowWidth < 800;
    return (
      <NavBar history={this.props.history} loading={loading}>
        {!selected ? (
          <Section backgroundColor={"rgba(0,0,0,.3)"} height="92vh">
            <InfoBox margin="2% auto 1% auto" width="90vw">
              <Row noWrap>
                <Input
                  name="sortBy"
                  label="Search"
                  value={search}
                  margin="auto 1% 1% 1%"
                  fontSize={mobile ? "2vw" : "1vw"}
                  width={mobile ? "100%" : "20vw"}
                  onChange={(v) =>
                    this.setState({ search: v }, this.queueFetch)
                  }
                />
                <Input
                  name="sortBy"
                  label="Search By"
                  options={searchOptions}
                  value={this.state.searchIndex}
                  margin="auto auto 1% 1%"
                  width={mobile ? "100%" : "15vw"}
                  fontSize={mobile ? "2vw" : "1vw"}
                  onChange={(v) => {
                    let sort = searchOptions[v.target.value];

                    this.setState(
                      {
                        sort:
                          sort.type == "info" ? `info.${sort.name}` : sort.name,
                        searchIndex: v.target.value,
                      },
                      this.fetchApplicants
                    );
                  }}
                />
              </Row>
              <Row>
                <Column width={mobile ? "80vw" : "20vw"}>
                  <Input
                    name="sortBy"
                    label="Sort By"
                    options={sortOptions.filter(
                      (option) => positionID || option.label !== "Status"
                    )}
                    value={this.state.sortIndex}
                    margin="auto 1% 1% 1%"
                    fontSize={mobile ? "2vw" : "1vw"}
                    onChange={(v) => {
                      let sort = sortOptions[v.target.value];

                      this.setState(
                        {
                          sort:
                            sort.type == "info"
                              ? `info.${sort.name}`
                              : sort.name,
                          sortIndex: v.target.value,
                        },
                        this.fetchApplicants
                      );
                    }}
                  />
                  <Checkbox
                    label="Descending Direction"
                    value={direction == -1}
                    margin="auto 0 auto 0"
                    onChange={() =>
                      this.setState(
                        {
                          direction: direction == 1 ? -1 : 1,
                        },
                        this.fetchApplicants
                      )
                    }
                  />
                </Column>
                <Column width={mobile ? "80vw" : "30vw"}>
                  <Input
                    name="position"
                    label="Selected Position"
                    options={positions}
                    margin="auto 1% 1% auto"
                    fontSize={mobile ? "2vw" : "1vw"}
                    value={positionFilter}
                    onChange={(v) =>
                      positions[v.target.value].label !== "All"
                        ? this.setState(
                            {
                              positionID:
                                positions[v.target.value].position._id,
                              position: positions[v.target.value].position,
                              statusID: null,
                              positionFilter: v.target.value,
                            },
                            this.fetchStatuses
                          )
                        : this.setState(
                            {
                              positionID: null,
                              position: {},
                              statusID: null,
                              positionFilter: v.target.value,
                            },
                            this.fetchStatuses
                          )
                    }
                  />
                  <Checkbox
                    label="Show Archived Applications"
                    value={showArchived}
                    margin="auto 0 auto 0"
                    onChange={() =>
                      this.setState(
                        {
                          showArchived: !showArchived,
                        },
                        this.fetchApplicants
                      )
                    }
                  />
                </Column>
                <Input
                  name="status"
                  label="Selected Status"
                  options={statuses}
                  margin="auto 1% 1% auto"
                  fontSize={mobile ? "2vw" : "1vw"}
                  width={mobile ? "80vw" : "30vw"}
                  value={statusID}
                  limit={limit}
                  page={page}
                  onChange={(v) =>
                    this.setState(
                      { statusID: v.target.value },
                      this.fetchApplicants
                    )
                  }
                />
              </Row>
              <Table
                data={this.getPage(applicants)}
                columns={columns.filter(
                  (column) => positionID || column.label !== "Status"
                )}
                onSelect={(v) => this.getForms(v)}
                onPage={(v) => this.setState({ page: v })}
                page={page}
                onLimit={(v) => this.setState({ limit: v, page: 1 })}
                limit={limit}
                total={applicants.length}
              />
            </InfoBox>
          </Section>
        ) : (
          <div>
            <Section backgroundColor={"rgba(0,0,0,.3)"} height="92vh">
              <Column
                backgroundColor="white"
                padding="1%"
                margin="0 auto 1% auto"
              >
                <Button
                  margin="0 auto 0 0"
                  width="5vw"
                  onClick={() =>
                    this.setState(
                      {
                        selected: null,
                        positionID:
                          positionFilter != 0 && positions[positionFilter]
                            ? positions[positionFilter].position._id
                            : null,
                        position: positions[positionFilter]
                          ? positions[positionFilter].position
                          : {},
                      },
                      this.fetchApplicants
                    )
                  }
                >
                  <FontAwesomeIcon
                    style={{ margin: "auto 5% auto auto" }}
                    icon={faArrowLeft}
                  />
                  Back
                </Button>
                <Text size={mobile ? "4vw" : "2vw"} margin="-2% 2% auto auto">
                  Status:{" "}
                  {applicant.applications &&
                    applicant.applications[0] &&
                    applicant.applications[0].status.label}
                </Text>
                <Column>
                  <Text size={mobile ? "6vw" : "3vw"}>
                    {selected.firstName + " " + selected.lastName}
                  </Text>
                  <Text size={mobile ? "4vw" : "2vw"}>
                    {applicant.applications &&
                      applicant.applications[0] &&
                      applicant.applications[0].position.label}
                  </Text>
                </Column>
                <Row margin="1% auto auto auto">
                  <Input
                    margin="auto auto 1% auto"
                    fontSize={mobile ? "2vw" : "1vw"}
                    width={mobile ? "80vw" : "30vw"}
                    label="Change Status"
                    options={[{ label: "Select a Status to change to" }].concat(
                      statusOptions
                    )}
                    onChange={(v) =>
                      this.setState({ forceNote: v.target.value })
                    }
                  />
                  <Input
                    margin="auto auto 1% auto"
                    fontSize={mobile ? "2vw" : "1vw"}
                    width={mobile ? "80vw" : "30vw"}
                    label="View Other Application"
                    options={appOptions}
                    value={selected.positionID}
                    onChange={(v) =>
                      this.setState(
                        { positionID: v.target.value },
                        this.fetchApplicant
                      )
                    }
                  />
                  <Input
                    name="form"
                    label="Select Form to View"
                    options={forms}
                    margin="auto auto 1% auto"
                    fontSize={mobile ? "2vw" : "1vw"}
                    width={mobile ? "80vw" : "30vw"}
                    onChange={(v) => {
                      let temp = forms[v.target.value];
                      let tempFields = [];
                      if (temp.answers.formVersion !== temp.form.version)
                        temp.form.versions.map((item) => {
                          if (item.versionId == temp.answers.formVersion) {
                            tempFields = item.fields;
                          }
                        });
                      else tempFields = temp.form.fields;
                      this.setState({
                        form: forms[v.target.value].form,
                        fields: tempFields,
                        values: forms[v.target.value].answers.values,
                        answerID: forms[v.target.value].answers._id,
                      });
                    }}
                  />
                </Row>
                <Row>
                  <Button
                    margin="auto 2% auto auto"
                    disabled={!curApp}
                    onClick={() => {
                      this.setState({ forceNote: "archive" });
                    }}
                  >
                    {curApp && curApp.archived
                      ? "Un Archive Application"
                      : "Archive Application"}
                  </Button>
                </Row>
              </Column>
              <Row noWrap={mobile ? "wrap" : "none"}>
                {mobile && (
                  <Button
                    margin="0% auto 1% auto"
                    onClick={() => this.setState({ showNotes: !showNotes })}
                  >
                    Toggle Notes
                  </Button>
                )}
                {(mobile && showNotes) || !mobile ? (
                  <Notes
                    onAdd={this.addNote}
                    onRemove={this.removeNote}
                    onCancel={() => this.setState({ forceNote: null })}
                    onNoteInput={(v) => this.setState({ noteText: v })}
                    text={noteText}
                    width={mobile ? "90vw" : "20vw"}
                    height={mobile ? "100vh" : "60vh"}
                    notes={selected.notes}
                    forceNote={forceNote}
                  />
                ) : null}
                {mobile && showNotes && (
                  <Button
                    margin="1% auto 1% auto"
                    onClick={() => this.setState({ showNotes: !showNotes })}
                  >
                    Toggle Notes
                  </Button>
                )}
                <ApplicantForm
                  form={form}
                  values={values}
                  fields={fields}
                  error={error}
                  deleteImage={this.deleteImage}
                  handleImages={this.handleImages}
                  changeValue={this.changeValue}
                  deleteAnswer={() =>
                    this.setState({
                      notify: {
                        message:
                          "Are you sure you want to delete this Applicant's form answers?",
                        accept: this.deleteAnswer,
                      },
                    })
                  }
                />
              </Row>
            </Section>
            <Notification
              onClose={() => this.setState({ error: {} })}
              open={error.general}
              title="Error getting forms"
              message={error.general}
            />
            <Notification
              onClose={() => this.setState({ notify: {} }, notify.accept)}
              open={notify.message}
              title="Confirmation"
              message={notify.message}
            />
          </div>
        )}
      </NavBar>
    );
  }
}

export default windowSize(Home);

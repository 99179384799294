import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  InfoBox,
  Text,
  Button,
  Input,
  Modal,
  Clickable,
  Table,
  Row,
  Divider,
  Column,
} from "../../globalComponents";
import Theme from "../../globalComponents/Theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faLink } from "@fortawesome/free-solid-svg-icons";
import Test from "../../assets/birdTitle.png";
import { stateOptions } from "../../globalResources/constants.js";

class Statuses extends React.Component {
  state = {
    processes: [],
    cities: [],
    states: [],
    notify: "",
    processOptions: [],
    forms: [],
    positions: [],
    newPosition: { qualifications: [], states: [], cities: [] },
    newInitialStatus: null,
    newLabel: "",
    newDescription: "",
    newQualifications: [],
  };

  fetchProcesses = (selected) => {
    this.setState({ loading: true });
    axios.get("/processes").then((res) => {
      if (res.data.success) {
        var temp = [{ label: "No Process Selected", value: 0 }];
        res.data.processes.map(
          (process, index) =>
            process.statuses.length > 0 &&
            temp.push({ label: process.name, value: process._id })
        );
        this.setState({
          processes: res.data.processes,
          processOptions: temp,
          error: {},
          loading: false,
          editStatuses: true,
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  fetchPositions = () => {
    this.setState({ loading: true });
    axios.get("/positions").then((res) => {
      if (res.data.success) {
        this.setState({
          positions: res.data.positions,
          error: {},
          loading: false,
        });
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  submit = (position) => {
    this.setState({ loading: true });
    axios
      .post("/position/", {
        id: position._id,
        label: position.label,
        description: position.description,
        initialStatus: position.initialStatus,
        process: position.process,
        qualifications: position.qualifications,
        cities: position.cities,
        states: position.states,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            newPosition: {
              qualifications: [],
              states: [],
              cities: [],
              cityInput: "",
            },
            newQualification: "",
          });
          this.fetchPositions();
        } else this.setState({ error: res.data.error, loading: false });
      });
  };

  getProcess(id) {
    let temp = "";
    this.state.processes.map((process) => {
      if (process._id === id) temp = process.name;
    });
    return temp;
  }
  changePosition(name, value) {
    let temp = this.state.newPosition;
    console.log(name + " " + value);
    if (name === "qualifications") {
      temp.qualifications.push(this.state.newQualification);
      this.setState({ newPosition: temp, newQualification: "" });
    } else {
      temp[name] = value;
      this.setState({ newPosition: temp });
    }
  }

  fetchSites = () => {
    this.setState({ loading: true });
    axios.get("/sites").then((res) => {
      if (res.data.success) {
        if (res.data.sites.length === 0)
          this.setState({ loading: false, sites: [], site: {} });
        else {
          var temp = [];
          res.data.sites.map((site, index) =>
            temp.push({ label: site.domain, value: site._id })
          );
          this.setState(
            {
              sites: temp,
              error: {},
              loading: false,
              site: temp.length > 0 ? temp[0].value : { emails: [] },
            },
            () => temp.length > 0 && this.getSite()
          );
        }
      } else if (res.data.error === "Not Authorized") {
        cookie.remove("token");
        window.location.reload();
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  generateLink = (position) => {
    this.setState({ loading: true });
    axios.get("/sites").then((res) => {
      if (res.data.success) {
        if (res.data.sites.length === 0)
          this.setState({ loading: false, sites: [], site: {} });
        else {
          var temp = [];
          let links = [];
          res.data.sites.map((site, index) =>
            links.push(`https://www.${site.domain}/jobs/?id=${position._id}`)
          );
          this.setState(
            {
              notify: ` ${links}`,
              loading: false,
            },
            () => temp.length > 0 && this.getSite()
          );
        }
      } else this.setState({ error: res.data.error, loading: false });
    });
  };

  componentDidMount() {
    this.fetchProcesses();
    this.fetchPositions();
  }

  render() {
    const {
      forms,
      processes,
      positions,
      newPosition,
      processOptions,
      newQualification,
      states,
      cities,
      loading,
      cityInput,
      selected,
      notify,
    } = this.state;
    const mobile = this.props.windowWidth < 800;
    return (
      <NavBar
        history={this.props.history}
        loading={loading}
        notify={notify}
        onConfirm={() => this.setState({ notify: "" })}
      >
        <InfoBox
          width={mobile ? "100vw" : "90vw"}
          padding="2% 0 2% 0"
          margin=" 1% auto auto auto"
        >
          <Text
            margin="auto auto -4% auto"
            size={mobile ? "6vw" : "3vw"}
            textAlign="center"
          >
            Positions
          </Text>
          <Button
            margin="0 1% 1% auto"
            fontSize={mobile ? "80%" : "100%"}
            onClick={() =>
              this.setState({
                newPosition: {
                  new: true,
                  qualifications: [],
                  cities: [],
                  states: [],
                },
              })
            }
          >
            Add New Position
          </Button>
          <Column margin="auto auto auto auto " padding={0}>
            <Divider width="90%" />
            {positions.map((position, index) => (
              <Row width="80vw">
                <Clickable
                  background={Theme.LIGHTER_GREY}
                  width="100%"
                  border=".1vw solid black"
                  onClick={() =>
                    this.setState({
                      selected: selected === index ? null : index,
                    })
                  }
                >
                  <Text>{position.label}</Text>
                </Clickable>
                <Column display={index === selected ? "flex" : "none"}>
                  <Row>
                    <Text>Process: {this.getProcess(position.process)}</Text>{" "}
                    <Clickable
                      margin="0% 2px 0 auto"
                      onClick={() => this.generateLink(position)}
                    >
                      <FontAwesomeIcon
                        icon={faLink}
                        style={{
                          width: "auto",
                          height: "2vh",
                          color: Theme.BLUE,
                        }}
                      />
                    </Clickable>
                    <Clickable
                      margin="0% 2px 0 1%"
                      onClick={() => this.setState({ newPosition: position })}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{
                          width: "auto",
                          height: "2vh",
                          color: Theme.BLUE,
                        }}
                      />
                    </Clickable>
                    <Clickable
                      margin="0% 2px 0 1%"
                      onClick={() => this.setState({ newPosition: position })}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{
                          width: "auto",
                          height: "2vh",
                          color: "red",
                        }}
                      />
                    </Clickable>
                  </Row>
                  <InfoBox margin="2% auto 2% auto">
                    <Text
                      margin="0 auto 2% auto"
                      textAlign="center"
                      size={mobile ? "4vw" : "2vw"}
                    >
                      Description
                    </Text>
                    <Text textAlign="center" size={mobile ? "3vw" : "1.5vw"}>
                      {" "}
                      {position.description}
                    </Text>
                  </InfoBox>
                  {position.states.length > 0 ? (
                    <Row>
                      {" "}
                      <Column width="40%">
                        <Text size={mobile ? "4vw" : "2.5vw"} margin="auto">
                          States
                        </Text>
                        {position.states &&
                          position.states.map((state) => <Text>{state}</Text>)}
                      </Column>
                      <Column width="40%">
                        <Text margin="auto" size={mobile ? "4vw" : "2.5vw"}>
                          Cities
                        </Text>

                        {position.cities &&
                          position.cities.map((cities) => (
                            <Text>{cities}</Text>
                          ))}
                      </Column>
                    </Row>
                  ) : (
                    <Text
                      margin="1% auto 1% auto"
                      textAlign="center"
                      size={mobile ? "4vw" : "2vw"}
                    >
                      Nationwide Position
                    </Text>
                  )}
                  <Text
                    textAlign="center"
                    margin="2% auto 0 auto"
                    size={mobile ? "3vw" : "1.5vw"}
                  >
                    Qualifications
                  </Text>
                  <ul
                    style={{
                      border: ".1vw solid black",
                      padding: "1% 2% 1% 2%",
                    }}
                  >
                    {position.qualifications.length > 0 ? (
                      position.qualifications.map((qualification) => (
                        <Text size={mobile ? "2vw" : ".8vw"}>
                          <li>{qualification}</li>
                        </Text>
                      ))
                    ) : (
                      <Text size={mobile ? "1vw" : ".8vw"} textAlign="center">
                        No Position Qualifications
                      </Text>
                    )}
                  </ul>
                </Column>
              </Row>
            ))}
          </Column>
        </InfoBox>{" "}
        <Modal
          open={newPosition.new || newPosition.label}
          onClose={() =>
            this.setState({
              newPosition: { qualifications: [], cities: [], states: [] },
              newQualification: "",
              cityInput: "",
            })
          }
        >
          <Input
            type="text"
            label="Position Title"
            value={newPosition.label}
            onChange={(v) => this.changePosition("label", v)}
          />
          <Input
            type="text"
            multiline
            label="Description"
            height="20vh"
            value={newPosition.description}
            onChange={(v) => this.changePosition("description", v)}
          />
          <Row>
            <Input
              width="70%"
              height="6vh"
              label="Add a Qualification"
              value={newQualification}
              onChange={(v) => this.setState({ newQualification: v })}
              onKeyUp={(e) => {
                if (e.key === "Enter")
                  this.changePosition("qualifications", newQualification);
              }}
            />
            <Button
              onClick={() =>
                this.changePosition("qualifications", newQualification)
              }
            >
              +
            </Button>
          </Row>
          <Text textAlign="center" margin="2% auto 0 auto" size="1vw">
            Qualifications
          </Text>
          <ul
            style={{
              display: "block",
              float: "left",
              flexDirection: "column",
              border: ".1vw solid black",
              padding: "2%",
            }}
          >
            {newPosition.qualifications.length > 0 ? (
              newPosition.qualifications.map((qualification, qualIndex) => (
                <Row>
                  {" "}
                  <Text size={mobile ? "1vw" : ".8vw"}>
                    <li>{qualification}</li>
                  </Text>
                  <Clickable
                    onClick={() => {
                      let temp = newPosition;
                      temp.qualifications.splice(qualIndex, 1);
                      this.setState({ newPosition: temp });
                    }}
                    margin="0 2% auto auto"
                  >
                    <FontAwesomeIcon
                      style={{ color: "red", height: "40px", width: "40px" }}
                      icon={faTimes}
                    />
                  </Clickable>
                </Row>
              ))
            ) : (
              <Text size={mobile ? "1vw" : ".8vw"} textAlign="center">
                No Position Qualifications
              </Text>
            )}
          </ul>
          <Input
            width="45%"
            name="process"
            label="Hiring Process"
            options={processOptions}
            value={newPosition.process}
            fontSize={mobile ? "4vw" : "1vw"}
            onChange={(v) => {
              this.changePosition(
                "process",
                v.target.value == 0 ? null : v.target.value
              );
            }}
          />
          <Row margin="auto auto 2% auto">
            <Column width={mobile ? "95%" : "45%"}>
              <Input
                label="States"
                options={stateOptions}
                value={0}
                margin="auto 1% 1% auto"
                fontSize={mobile ? "4vw" : "1vw"}
                onChange={(v) => {
                  let temp = newPosition.states;
                  temp.push(v.target.value);
                  this.changePosition("states", temp);
                }}
              />
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  border: "2px solid black",
                  width: "95%",
                  minHeight: "30px",
                  margin: "0 auto 0 auto",
                  padding: "1%",
                }}
              >
                {newPosition.states.length == 0 ? (
                  <Text>Nationwide Position</Text>
                ) : (
                  newPosition.states.sort().map((state, stateIndex) => (
                    <Row>
                      <Text size={mobile ? "2vw" : "1vw"}>{state}</Text>
                      <Clickable
                        margin="auto 0 auto auto"
                        onClick={() => {
                          let temp = newPosition.states;
                          temp.splice(stateIndex, 1);
                          this.changePosition("states", temp);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{
                            height: "2vh",
                            width: "auto",
                            color: "red",
                          }}
                        />
                      </Clickable>
                    </Row>
                  ))
                )}
              </div>
            </Column>
            <Column width={mobile ? "95%" : "45%"}>
              <Input
                label="Cities"
                margin="auto 1% 1% auto"
                fontSize={mobile ? "4vw" : "1vw"}
                value={cityInput}
                onChange={(v) => {
                  this.setState({ cityInput: v });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter" && newPosition.states.length > 0) {
                    let temp = newPosition.cities;
                    temp.push(cityInput);
                    this.changePosition("cities", temp);
                  }
                }}
              />

              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  border: "2px solid black",
                  minHeight: "30px",
                  width: "95%",
                  margin: "0 auto 0 auto",
                  padding: "1%",
                }}
              >
                {newPosition.states.length == 0 ? (
                  <Text>Select a State First</Text>
                ) : (
                  newPosition.cities.sort().map((city, cityIndex) => (
                    <Row>
                      <Text size={mobile ? "2vw" : "1vw"}>{city}</Text>
                      <Clickable
                        margin="auto 0 auto auto"
                        onClick={() => {
                          let temp = newPosition.cities;
                          temp.splice(cityIndex, 1);
                          this.changePosition("cities", temp);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{
                            height: "2vh",
                            width: "auto",
                            color: "red",
                          }}
                        />
                      </Clickable>
                    </Row>
                  ))
                )}
              </div>
            </Column>
          </Row>
          <Button
            margin="2% auto auto auto"
            onClick={() => {
              this.submit(newPosition);
            }}
          >
            Save New Position
          </Button>
        </Modal>
      </NavBar>
    );
  }
}

export default windowSize(Statuses);

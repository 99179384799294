import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import { NavBar, Section, InfoBox, Text } from "../../globalComponents";
import Theme from "../../globalComponents/Theme.js";

class Home extends React.Component {
  state = {
    components: [],
  };
  render() {
    const mobile = this.props.windowWidth < 800;
    return (
      <NavBar history={this.props.history}>
        <Section backgroundColor={"rgba(0,0,0,.3)"} height="92vh">
          <InfoBox margin="2% auto auto auto" width="90vw" maxWidth="1200px">
            <Text size={mobile ? "4vw" : "2.5vw"}>Welcome to Aviary!</Text>
            <Text size={mobile ? "3vw" : "2vw"}>
              The CMS system of
              <a
                href="https://nitely.dev"
                target="/"
                style={{ textDecoration: "none", color: Theme.ORANGE }}
              >
                {" Nitely Development"}
              </a>
            </Text>
            <Text
              margin="2%"
              size={mobile ? "2.5vw" : "1.3vw"}
              textAlign="center"
            >
              Aviary is the content management system where all of our clients
              go to manage their custom websites and make changes to their
              content. Aviary is still in development so if you see any issues
              or have any suggestions let us know by contacting
              support@nitely.dev. Thank You!
            </Text>
            <Text
              margin="2%"
              size={mobile ? "2.5vw" : "1.3vw"}
              textAlign="center"
            >
              To edit the information displayed on your site head over to the
              Sites page by clicking "Sites" on the navigation bar then select
              "Site Info".
            </Text>
            <Text
              margin="2%"
              size={mobile ? "2.5vw" : "1.3vw"}
              textAlign="center"
            >
              To add or change entries in your feeds for blogs, news or other
              lists of information on your website, click the "Sites" dropdown
              and select "Feeds"
            </Text>

            <Text
              margin="2%"
              size={mobile ? "2.5vw" : "1.3vw"}
              textAlign="center"
            >
              If you'd like to add features to your site or upgrade your plan to
              include some of our other features such as recruitment contact us
              at support@nitely.dev to get started.
            </Text>
            <Text
              margin="2%"
              size={mobile ? "2.5vw" : "1.3vw"}
              textAlign="center"
            >
              Emergency Support Phone Number: (629)209-8341
            </Text>
          </InfoBox>
        </Section>
      </NavBar>
    );
  }
}

export default windowSize(Home);

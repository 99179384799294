import React, { Component } from "react";
import styled from "styled-components";
import Theme from "./Theme.js";
import { Button, Row, Clickable, Text } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Input = styled.input`
  box-shadow: ${(props) =>
    props.shadow ? `0px 0px 1vw ${Theme.BLUE}` : "none"};
  color: ${(props) => props.textColor};
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${(props) => props.fontSize || "100%"};
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  padding: 1% 2% 1% 2%;
  outline: none;
  ::placeholder {
    color: ${(props) => props.hintColor};
  }
`;

const Label = styled.div`
  position: ${(props) => props.position};
  width: ${(props) => props.width || "auto"};
  font-family: ${Theme.MAIN_FONT};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "auto")};
  font-weight: bold;
  display: ${(props) => (props.hidden ? "none" : "default")};
  margin: 1% auto 1% 1%;
  margin: ${(props) => (props.margin ? props.margin : null)};
  color: ${(props) => (props.color ? props.color : "black")};
`;

const ErrorText = styled.div`
  color: red;
  font-size: 100%;
  margin-left: 1%;
`;

export default class Upload extends Component {
  render() {
    const handleUpload = () => {
      document.getElementById("hiddenFileInput").click();
    };
    const {
      onChange,
      error,
      name,
      placeholder,
      containerStyle,
      multiline,
      label,
      fontSize,
      labelSize,
      labelColor,
      textColor,
      backgroundColor,
      type,
      onKeyUp,
      errorColor,
      borderColor,
      borderRadius,
      border,
      margin,
      width,
      height,
      mobile,
      onRemove,
      files = [],
      previewWidth,
      previewHeight,
      single,
    } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: width || "100%",
          height: height || "auto",
          margin: margin || "auto",
        }}
      >
        <Label
          fontSize={fontSize}
          labelColor={labelColor}
          labelSize={labelSize}
        >
          {label}
        </Label>
        <Button disabled={single && files.length > 0} onClick={handleUpload}>
          Select File
        </Button>
        <Text
          textAlign="center"
          margin="auto"
          style={{ display: single && files.length > 0 ? "flex" : "none" }}
        >
          Delete Image to Replace
        </Text>
        <Row>
          {files &&
            files.map((image, index) => (
              <div
                style={{
                  position: "relative",
                  border: "2px solid black",
                  margin: "1% auto 1% auto",
                  color: "black",
                  width:
                    files && files.length > 4
                      ? window.innerHeight / (files.length * 1.2)
                      : window.innerHeight / 3.5,
                }}
              >
                <Clickable
                  onClick={() => onRemove(index)}
                  style={{
                    position: "absolute",
                    width: "40px",
                    right: 0,
                    marginRight: "-1%",
                    marginTop: "-1%",
                    height: "40px",
                  }}
                >
                  <FontAwesomeIcon
                    style={{ height: "100%", width: "100%", color: "#d80404" }}
                    icon={faTimesCircle}
                  />
                </Clickable>
                {typeof image == "string" && image.includes("data:video") ? (
                  <video
                    loop
                    id={`vid`}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    autoPlay
                    muted
                  >
                    <source src={image} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    key={`input${index}`}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={image}
                  />
                )}
              </div>
            ))}
        </Row>
        <input
          id="hiddenFileInput"
          multiple={!single}
          onChange={(e) => {
            onChange(e.target.files);
          }}
          type="file"
          style={{ display: "none" }}
        />
        {!!error && <ErrorText>{error || "error"}</ErrorText>}
      </div>
    );
  }
}
